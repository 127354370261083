import {FC} from "react";
import {Box, BoxProps} from "@mui/material";
import {makeStyles} from "tss-react/mui";

import {File} from "@plumeuk/shapeshift-common/files";
import {IFile} from "@plumeuk/shapeshift-types";

import {IconFileImage} from "../icons/iconFileImage";
import {IconFileLink} from "../icons/iconFileLink";
import {IconFileVideo} from "../icons/iconFileVideo";
import {IconDownload} from "../icons/iconDownload";
import {redditPalette} from "../../constants";

type FilesProps = {
	files: IFile[]
} & BoxProps

const FileIcon: FC<Pick<IFile, "mime">> = ({mime}) => {
	if (mime.includes("image")){
		return <IconFileImage />
	}
	if (mime.includes("video")){
		return <IconFileVideo />
	}
	if (mime.includes("application")){
		return <IconFileLink />
	}
	return <IconFileLink />
}

const useFileStyles = makeStyles()((theme) => ({
	wrapper: {
		"& [class*='file']": {
			padding: "28px",
			color: redditPalette.textPrimary,
			backgroundColor: redditPalette.bg,
			[theme.breakpoints.down("sm")]: {
				padding: "20px"
			},
			"& [class*='fileIcon']": {
				margin: 0,
				padding: 0,
				background: "none",
				borderRadius: "none",
				"& svg": {
					display: "block",
					fontSize: "70px"
				},
				[theme.breakpoints.down("sm")]: {
					"& svg": {
						fontSize: "50px"
					}
				}
			},
			"& [class*='contentContainer']": {
				margin: 0,
				padding: "0 28px",
				borderRight: `1.5px solid ${redditPalette.grey200}`,

				"& [class*='title']": {
					fontSize: "24px",
					fontWeight: 800,
					color: "inherit",
					lineHeight: 1
				},

				[theme.breakpoints.down("sm")]: {
					padding: "0 18px",
					borderRight: "none",

					"& [class*='title']": {
						fontSize: "20px"
					}
				}
			},
			"& [class*='description']": {
				fontSize: "17px",
				color: "inherit",
				paddingTop: "8px",
				[theme.breakpoints.down("sm")]: {
					fontSize: "16px",
					paddingTop: "8px"
				}
			},
			"& [class*='fileDownloadAnchor']": {
				color: "inherit",
				padding: "30px 24px",
				margin: "-28px",
				marginLeft: "0",

				"& [class*='downloadButton']": {
					margin: 0,
					padding: 0,
					border: "none",
					color: "inherit",

					"& [class*='MuiTypography-root']": {
						fontSize: "15px",
						color: "inherit",
						fontWeight: "600"
					},
					"& svg": {
						fontSize: "32px"
					}
				},

				[theme.breakpoints.down("sm")]: {
					border: `1px solid ${redditPalette.grey400}`,
					borderRadius: "100px",
					textAlign: "center",
					padding: "12px",
					margin: 0,
					marginTop: "25px",

					"& [class*='downloadButton']": {
						flexDirection: "row",
						gap: "10px",

						"& svg": {
							fontSize: "24px"
						}
					}
				}
			}
		}
	}
}));

export const Files: FC<FilesProps> = ({files, className, ...props})=> {
	const {classes: fileClasses} = useFileStyles()

	return (
		<Box className={`${fileClasses.wrapper} ${className}`} {...props}>
			{
				files.map((file, i)=> (
					<File
						key={i}
						file={file}
						componentOverrides={{
							fileIcon: <FileIcon mime={file.mime} />,
							downloadIcon: <IconDownload />
						}}
					/>
				))
			}
		</Box>
	)
}