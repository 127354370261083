import {FC} from "react";
import rehypeRaw from "rehype-raw";
import {ReactMarkdown, ReactMarkdownOptions} from "react-markdown/lib/react-markdown";
import {useFileBaseUrl} from "@plumeuk/shapeshift-common/hooks";
import {makeStyles} from "tss-react/mui";
import EmbedLink from "../embedLink/embedLink";

const useStyles = makeStyles()((_theme) => ({
	wysiwyg: {
		[_theme.breakpoints.up("xxl")]: {
			fontSize: "125%"
		},
		"& p": {
			whiteSpace: "break-spaces",
			fontSize: "1.125em"
		},
		"& img": {
			maxWidth: "100%"
		},
		"& iframe": {
			border: "1px solid lightgray",
			borderRadius: "4px",
			width: "100%",
			aspectRatio: "1.8/1", // youtube video ratio
			height: "auto"
		}
	},
	embedLink: {}
}));

export const WYSIWYG: FC<ReactMarkdownOptions> = ({className, ...props}) => {
	const {classes, cx} = useStyles();
	const {getUrl} = useFileBaseUrl();

	return (
		<ReactMarkdown
			className={cx(classes.wysiwyg, className)}
			rehypePlugins={[rehypeRaw]}
			components={{
				u: (uProps) => <u style={{textDecoration: "underline"}} {...uProps} />,
				img: ({src, ...imgProps}) =>
					<img
						src={getUrl(src ?? "")}
						{...imgProps}
					/>,
				a: (props)=> {
					return <EmbedLink className={classes.embedLink} {...props} />
				}
			}}
			{...props}
		/>
	);
}