import {createSvgIcon} from "@plumeuk/shapeshift-common/icon";

export const IconAll = createSvgIcon(
	<>
		<g id="icon_all_outline">
			<path id="Vector" d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34873 18.9426 4.80688 17.0679 2.93215C15.1931 1.05742 12.6513 0.0029116 10 0V0ZM15 17.171V6H13.75V17.894C12.8837 18.3094 11.9544 18.5777 11 18.688V10H9.75V18.737C8.6191 18.7074 7.50489 18.457 6.47 18H7V14H5.75V17.642C4.08742 16.7194 2.7705 15.2804 1.9984 13.5428C1.2263 11.8052 1.04102 9.86339 1.47058 8.01112C1.90013 6.15884 2.92117 4.49681 4.37926 3.2764C5.83735 2.05598 7.6532 1.34355 9.55217 1.24686C11.4511 1.15016 13.33 1.67446 14.9045 2.74046C16.479 3.80646 17.6636 5.35618 18.279 7.15525C18.8945 8.95431 18.9074 10.9049 18.3158 12.7119C17.7242 14.519 16.5603 16.0842 15 17.171Z" fill="#0F1A1C"/>
		</g>
	</>,
	"All",
	"0 0 20 20"
);

