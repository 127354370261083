import {Box, BoxProps, LinearProgress} from "@mui/material";
import {FC, useCallback, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {ICourse} from "@plumeuk/shapeshift-types/ICourse";
import {IAPIDrivenChildrenFunction} from "@plumeuk/shapeshift-common/types/ICommon";
import {LibrarySearch, LibraryCarousels} from "@plumeuk/shapeshift-common/library";
import {useLibraryTags, useCourses} from "@plumeuk/shapeshift-common/hooks";
import {ILibraryItem, ILibrarySearch, isILibraryCourse, isILibraryGroup} from "@plumeuk/shapeshift-types";
import {redditPalette} from "../../constants";
import {LibraryTags} from "./LibraryTags";
import {useNavigate} from "react-router-dom";
import {LibraryCarousel} from "../../components/libraryCarousel/libraryCarousel";

interface IPropsCustom {
	data?: ICourse[],
	children?: IAPIDrivenChildrenFunction<ICourse[] | undefined>,
}

export type IProps = Omit<BoxProps, "children"> & IPropsCustom;

const useStyles = makeStyles()((theme) => ({
	library:{
		overflow: "visible"
	},
	title: {
		margin: "10px",
		fontSize: "34px",
		lineHeight: "35px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "35px"
		}
	},
	searchWrapper: {
		margin: "0 60px",
		[theme.breakpoints.down("md")]: {
			margin: "0 20px"
		}
	},
	search: {
		alignItems: "center",
		margin: 0,
		marginTop: "-40px",
		marginBottom: "41px",
		["& [class*='searchBox-searchBox']"]: {
			padding: "0px 28px",
			backgroundColor: "white",
			borderRadius:  "9999px",
			boxShadow: "0px 4px 20px 0px rgba(46, 52, 54, 0.08)",
			border: "none !important",
			height: "unset",
			["& [class*='MuiInput']"]: {
				fontSize: "21px",
				height: "80px",
				color: redditPalette.darkGrey,
				boxSizing: "border-box"
			}
		},
		["& [class*='select-instructorSelect-select']"]: {
			display: "none"
		},
		["& [class*='outlineButton-tagsBtn-select']"]: {
			width: "auto",
			minWidth: "100px",
			height: "44px"
		},
		["& > *"]: {
			height: "auto"
		},
		[theme.breakpoints.down("sm")]: {
			marginTop: "-32px",

			["& [class*='outlineButton-tagsBtn-select']"]: {
				width: "100%"
			},
			["& [class*='searchBox-searchBox']"]: {
				["& [class*='MuiInput']"]: {
					height: "64px",
					fontSize: "18px"
				}
			}
		}
	},
	carouselsContainer: {
		margin: "150px 0"
	},
	libraryItem: {

	}
}));


export const LibraryContent: FC<IProps> = ({className, data, children, ...props}) => {
	const navigate = useNavigate();
	const {classes, cx} = useStyles();
	const [searchValue, setSearchValue] = useState<ILibrarySearch>({searchTerm: "", includeEnrolled: true, includeGroups: true, includeCourses: true});
	const {tags} = useLibraryTags({sortBy: "popular", limit: 5});
	const {courses: enrolledCourses, apiResult: enrolledCoursesApiResult} = useCourses();

	const handleGoToItemAction = useCallback((e: ILibraryItem) => {
		if(isILibraryCourse(e))
			navigate(e.enrolled ? `/learn/course/${e.slug}` : `/discover/${e.slug}`)

		else if(isILibraryGroup(e))
			navigate(`/discover/collection/${e.slug}`)
	}, [])

	return (
		<Box className={cx(classes.library, className)} {...props}>

			<Box className={classes.searchWrapper}>
				<LibrarySearch
					className={classes.search}
					value={searchValue}
					onChange={setSearchValue}
					textFieldProps={{
						inputProps: {
							placeholder: "What are you looking for?"
						}
					}}
				/>

				{/* TAGS */}
				{
					tags && tags.length > 0 &&
					<LibraryTags
						tags={tags}
						value={searchValue.tags}
						onChange={setSearchValue}
					/>
				}
			</Box>
			{enrolledCoursesApiResult.isLoading
				? <LinearProgress />
				: <LibraryCarousels
					className={classes.carouselsContainer}
					onGoToItemAction={handleGoToItemAction}
					searchValue={searchValue}
					componentOverrides={{libraryCarousel: (baseProps) =>
						<LibraryCarousel {...baseProps} />}}
				/>
			}
		</Box>
	);
}

export default LibraryContent;