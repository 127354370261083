
import {createSvgIcon} from "@plumeuk/shapeshift-common/icon";

export const IconGoogleSheet = createSvgIcon(
	<>
		<path d="m77.757 31.995v-27.995h-50.561a4.948 4.948 0 0 0 -4.949 4.948v110.104a4.948 4.948 0 0 0 4.949 4.948h73.608a4.948 4.948 0 0 0 4.949-4.948v-87.057z" fill="#00ac47"/>
		<path d="m77.757 4v27.995h27.996z" fill="#00832d"/>
		<path d="m34.31 49.842v43.299h59.38v-43.299zm52.577 18.247h-19.485v-11.443h19.485zm-26.289-11.443v11.444h-19.485v-11.444zm-19.485 18.248h19.485v11.443h-19.485zm26.29 11.443v-11.443h19.484v11.443z" fill="#fff"/>
	</>,
	"GoogleSheet",
	"0 0 130 130"
);
