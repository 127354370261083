import {Box, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {FC, ReactElement} from "react";
import {ReactMarkdownProps} from "react-markdown/lib/complex-types";
import {IconGoogleDoc} from "../icons/google/iconGoogleDoc";
import {IconGoogleSheet} from "../icons/google/iconGoogleSheet";
import {IconGoogleSlide} from "../icons/google/iconGoogleSlide";
import {IconGoogleDrive} from "../icons/google/iconGoogleDrive";
import LinkIcon from "@mui/icons-material/Link";

type Props = Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, "ref">
& ReactMarkdownProps

type Platform = "doc" | "sheet" | "slide" | "drive" | "generic";

const icons: {[P in Platform]: ReactElement} = {
	doc: <IconGoogleDoc />,
	sheet: <IconGoogleSheet />,
	slide: <IconGoogleSlide />,
	drive: <IconGoogleDrive />,
	generic: <LinkIcon />
}

function getPlatform(href: string): Platform {
	switch (true) {
		case href.includes("docs.google.com/document"):
			return "doc"
		case href.includes("docs.google.com/spreadsheets"):
			return "sheet"
		case href.includes("docs.google.com/presentation"):
			return "slide"
		case href.includes("drive.google.com"):
			return "drive"
		default:
			return "generic"
	}
}

const useStyles = makeStyles()((theme) => ({
	embedLinkAsTile: {
		fontSize: "inherit",
		color: "inherit",
		display: "inline-block",
		padding: "5px 8px",
		borderRadius: "8px",
		border: "1px solid #e9e9e9",
		textDecoration: "none",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		},
		// only if they are stacked underneath each other, otherwise remove
		"& + &":{
			marginTop: "4px"
		}
	}
}));


export const EmbedLinkAsTile: FC<Props> = ({children, ...props}) => {
	const {classes} = useStyles();

	const platform = getPlatform(props.href || "");
	const Icon = icons[platform];

	return (
		<a {...props} rel="noreferrer" href={"https://" + props.href?.replace("https://", "")?.replace("http://", "")} target="_blank" className={classes.embedLinkAsTile}>
			<Box display="flex" flexDirection="row" alignItems="center" gap="6px">
				{Icon}
				{(children && children.length > 0) && <Typography variant="inherit">{children}</Typography>}
			</Box>
		</a>
	);
};

export default EmbedLinkAsTile;