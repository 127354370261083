import {FC, useEffect, useState} from "react";
import {useFileBaseUrl} from "@plumeuk/shapeshift-common/hooks";
import {makeStyles} from "tss-react/mui";
import {redditPalette} from "../constants";

type IProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const useStyles = makeStyles<{loaded: boolean, queueFlip: boolean}>()((_theme, {loaded, queueFlip}) => ({
	image: {
		//> Requires more work before enabling, can cause a flicker
		//opacity: (queueFlip && !loaded) ? 0 : 1,
		//transition: "opacity .3s ease-in"

		color: "grey",
		"boxShadow": "inset 0px 0px 0px 1000px " + redditPalette.grey100
	}
}));

export const Image: FC<IProps> = ({className, ...props}) => {
	const [queueFlip, setQueueFlip] = useState(false)
	const [loaded, setLoaded] = useState<boolean>(false)
	const {classes, cx} = useStyles({loaded, queueFlip});
	const {getUrl} = useFileBaseUrl();


	useEffect(() => {
		if(queueFlip){
			setTimeout(() => {
				setLoaded(true)
				setQueueFlip(false)
			}, 100)
		}
	}, [queueFlip])


	const handleOnLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>): void => {
		props.onLoad?.(e);
		setQueueFlip(true);
	}

	return (
		<img
			loading="lazy"
			className={cx(classes.image, className)}
			{...props}
			src={props.src ? getUrl(props.src) : undefined}
			onLoad={handleOnLoad}
		/>
	);
}