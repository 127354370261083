import {Box, BoxProps} from "@mui/material";
import React, {FC, useContext, useEffect, useState, isValidElement, cloneElement, ReactNode, useRef} from "react";
import {NavBar, SideMenu} from "@plumeuk/shapeshift-common/pageBase";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import {redditPalette, sideMenuWidth} from "../../constants";
import {AuthContext, useApi} from "@plumeuk/shapeshift-identity";
import {IconHome} from "../icons/iconHome";
import {IconLearning} from "../icons/iconTopicLearning";
import {IconComments} from "../icons/iconComments";
import {IconAll} from "../icons/iconAll";
import {MobileMenu} from "./mobileMenu";
import {MenuIconContainer} from "./menuIconContainer";
import {IconSpreadsheetOutline} from "../icons/iconSpreadsheetOutline";
import {PageBaseContext} from "../../contexts/pageBaseContext";
const logo = "/grow_logo_min.png"

interface ICustomProps {
	children: React.ReactNode,
	contentWidth?: string,
	sideBarContent?: ReactNode,
	disableSideBar?: boolean
}

interface IStyleProps {
	sideMenuOpen: boolean,
	hasNavBar: boolean,
	contentWidth?: string
}

const useStyles = makeStyles<IStyleProps>()((theme, {sideMenuOpen, hasNavBar, contentWidth}) => ({
	pageBase: {
		height: "100%"
	},
	navBar: {
		"[class*='toolBar']": {
			height: theme.toolbarHeight
		},
		"[class*='logoContainer']": {
			width: "auto",
			display: "flex",
			alignItems: "center",
			borderRight: `1px solid ${redditPalette.grey300}`,
			paddingRight: "26px",

			"& img": {
				margin: "0",
				height: "67px",
				display: "block",
				transition: "all .3s ease-in-out",
				position: "relative"
			},
			"& [class*='navBarLogo']": {
				height: "unset"
			}
		},
		"[class*='NavBarTabs']": {
			paddingLeft: "19px",
			justifyContent: "flex-start",
			"[class*='flexContainer']": {
				gap: "16px"
			}
		},
		"[class*='pageBaseProfile']": {
			"& [class$='bellIconContainer']": {
				display: "none"
			},
			"[class*='MuiTypography-body1']": {
				fontWeight: 600
			}
		},
		[theme.breakpoints.down("sm")]: {
			"[class*='logoContainer']": {
				borderRight: "none",
				"& img": {
					height: "32px"
				}
			},
			"[class*='NavBarTabs']": {
				display: "none"
			},
			"[class$='iconContainer']": {
				margin: 0
			},
			"[class$='label']": {
				display: "none"
			}
		}
	},
	navBarToggleWrapper: {
		display: "flex",
		paddingRight: "8px",
		[theme.breakpoints.up("sm")]:{
			display: "none"
		}
	},
	navBarToggles: {
		background: "none",
		padding: 0,
		width: "40px",
		height: "40px",
		color: "#0F1A1C",
		border: 0,
		opacity: 1,
		minWidth: "40px",
		"&:hover": {
			background: "none"
		}
	},
	background: {
		backgroundColor: theme.palette.background.default,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: "-1",
		position: "fixed"
	},
	navBarSpacer: {
		width: "100%",
		height: theme.toolbarHeight
	},
	contentContainer: {
		scrollBehavior: "smooth",
		width: sideMenuOpen ? `calc(100% - ${sideMenuWidth})` : "100%",
		position: "absolute",
		overflowX: "hidden",
		transition: "all .3s ease-in-out",
		left: sideMenuOpen ? sideMenuWidth : "0px",
		boxSizing: "border-box",
		overflowY: "auto",
		padding: "25px",
		top: hasNavBar ? theme.toolbarHeight : 0,
		backgroundColor: redditPalette.white,
		height: hasNavBar ? `calc(100% - ${theme.toolbarHeight})` : "100%",
		[theme.breakpoints.down("md")]: {
			left: 0,
			width: "100%"
		}
	},
	sideMenu: {
		left: sideMenuOpen ? "0%" : "-100%",
		height: `calc(100% - ${theme.toolbarHeight})`,
		top: hasNavBar ? theme.toolbarHeight : 0,
		"& [class*='paper']": {
			backgroundColor: redditPalette.grey100 + " !important",
			paddingTop: 0 + " !important"
		},

		[theme.breakpoints.down("sm")]:{
			"&, .MuiDrawer-docked, .MuiDrawer-paper, .MuiPaper-root": {
				width: "100% !important",
				maxWidth: "400px"
			}
		}
	},
	content: {
		minHeight: "100%",
		boxSizing: "border-box",
		width: "100%",
		maxWidth: contentWidth ?? "1000px",
		margin: "auto"

	},
	center : {
		width : "100%",
		display : "flex",
		justifyContent : "center"
	},
	navIcon: {
		marginTop: "3px",
		maxWidth: "20px",
		display: "inline-block",
		"& path": {
			fill: "rgb(23, 46, 53)"
		}
	},
	navIconActive: {
		"& path": {
			fill: theme.palette.secondary.main
		}
	}
}));

type IProps = ICustomProps & Omit<BoxProps, "children">;
type pages = "My Collection" | "Discover" | "CMS" | "Help";

type routeCallback = {
	[key in pages]: string;
};

const routesMap: routeCallback = {
	"My Collection": "/my-collection",
	"Discover": "/discover",
	"CMS": "",
	"Help": ""
	// "Performance": "/performance",
	// "Feedback": "/feedback"
}

export const PageBase: FC<IProps> = ({sideBarContent, disableSideBar, contentWidth, children, className, ...props}) => {
	const mq = useMq();
	const [contextState, setContextState] = useContext(PageBaseContext)
	const [{data: hasCMSAccess}] = useApi<boolean>("/api/access/cms");
	const {user} = useContext(AuthContext);
	const location = useLocation();
	const [navMenuOpen, setNavMenuOpen] = useState(false);
	const sideMenuOpen = contextState.sidebarOpen;
	const setSideMenuOpen = (e: boolean): void => setContextState(prev => ({...prev, sidebarOpen: e}))
	const {classes, cx} = useStyles({sideMenuOpen: sideMenuOpen, hasNavBar: true, contentWidth});
	const navigate = useNavigate();
	const [tabs, setTabs] = useState<pages[]>([]);
	const [firstRender, setFirstRender] = useState(true);
	const contentContainer = useRef<HTMLElement>(null);

	useEffect(() => setFirstRender(false), [])

	useEffect(() => {
		if(!firstRender){
			setContextState(prev => ({...prev, contentContainerRef: contentContainer}))
		}
	}, [firstRender])

	useEffect(() => setSideMenuOpen(!disableSideBar && mq.desktop), [disableSideBar, mq.desktop]);

	useEffect(() => {
		if(contextState.sidebarEnabled === false)
			setSideMenuOpen(false)
	}, [contextState]);

	useEffect(() => {
		if(!user) return;

		const tabs: pages[] = ["Discover", "My Collection", "Help"];
		if(hasCMSAccess)
			tabs.push("CMS")

		setTabs(tabs);
	}, [user, hasCMSAccess])

	let pageSelected: pages | undefined = undefined;
	Object.keys(routesMap).forEach(e => {
		const page = e as pages;
		if(!pageSelected && location.pathname.startsWith(routesMap[page]))
			pageSelected = page;
	})

	const handlePageSelect = (page: pages): void => {
		if(page === "CMS"){
			if(!process.env.REACT_APP_API_HOST)
				throw new Error("REACT_APP_API_HOST not set, cannot navigate to CMS")
			window.location.href = process.env.REACT_APP_API_HOST
		}
		else if(page === "Help"){
			if(!process.env.REACT_APP_HELP_URL)
				throw new Error("REACT_APP_HELP_URL not set, cannot navigate to Help")
			window.location.href = process.env.REACT_APP_HELP_URL
		}
		else {
			navigate(routesMap[page]);
		}
	}

	const handleContentClick = (): void => {
		if(!mq.desktop && sideMenuOpen) setSideMenuOpen(false);
	}

	const tabIcons = [
		<IconHome key={"icon_" + 1} className={cx(classes.navIcon, pageSelected === "Discover" ? classes.navIconActive : "")} />,
		<IconLearning key={"icon_" + 2} className={cx(classes.navIcon, pageSelected === "My Collection" ? classes.navIconActive : "")} />,
		<IconComments key={"icon_" + 3} className={cx(classes.navIcon, pageSelected === "Help" ? classes.navIconActive : "")} />,
		<IconSpreadsheetOutline key={"icon_" + 4} className={cx(classes.navIcon)} />,
		<IconAll key={"icon_" + 5} className={cx(classes.navIcon)} />
	]

	return (
		<Box className={cx(classes.pageBase, className)} {...props}>
			<NavBar<pages>
				className={classes.navBar}
				tabs={tabs}
				onBurgerAction={() => {}}
				onLogoClick={() => navigate("/discover")}
				onPageSelect={handlePageSelect}
				pageSelected={pageSelected}
				logoPath={logo}
				disableSideBar={disableSideBar}
				disableAccountSettings
				icons={tabIcons}
				componentOverrides={{
					menuIconContainer: () => (
						<MenuIconContainer
							sideMenuDisabled={contextState.sidebarEnabled === false}
							sideMenuOpen={sideMenuOpen}
							navMenuOpen={navMenuOpen}
							setNavMenuOpen={setNavMenuOpen}
							setSideMenuOpen={setSideMenuOpen}
						/>
					)
				}}
			/>
			<MobileMenu
				open={navMenuOpen}
				onChange={()=> setNavMenuOpen(false)}
			/>
			<SideMenu
				className={classes.sideMenu}
				open={sideMenuOpen}
				width={sideMenuWidth}
			>
				{isValidElement(sideBarContent) ? cloneElement(sideBarContent, {...sideBarContent?.props ?? {}, setSideMenuOpen}) : undefined}
			</SideMenu>
			<Box className={classes.contentContainer} onClick={handleContentClick} ref={contentContainer}>
				<Box className={classes.content}>
					{children}
				</Box>
			</Box>
			<Box className={classes.background} />
		</Box>
	);
};
