import {createSvgIcon} from "@plumeuk/shapeshift-common/icon";

export const IconComments = createSvgIcon(
	<>
		<g id="icon_comments_outline">
			<path id="Vector" d="M3.62504 12.069V13.319C2.89944 13.2319 2.23112 12.8813 1.74715 12.3337C1.26317 11.7861 0.997287 11.0798 1.00004 10.349V4C0.996038 3.21039 1.30506 2.45136 1.85946 1.8891C2.41386 1.32683 3.16845 1.00713 3.95804 1H12.042C12.7649 1.00707 13.4603 1.27761 13.9979 1.76089C14.5355 2.24417 14.8783 2.90698 14.962 3.625H13.707C13.6283 3.24012 13.4201 2.89381 13.1172 2.64366C12.8143 2.3935 12.4349 2.25458 12.042 2.25H3.95804C3.49989 2.25685 3.06308 2.44476 2.74304 2.77266C2.423 3.10057 2.24576 3.54182 2.25004 4V10.345C2.24593 10.7467 2.38046 11.1375 2.63093 11.4515C2.8814 11.7656 3.23251 11.9837 3.62504 12.069ZM14.025 16.959H16.046C16.8297 16.9569 17.5806 16.6443 18.1343 16.0897C18.6879 15.5351 18.9992 14.7837 19 14V7.959C18.9992 7.17464 18.6874 6.42262 18.1328 5.8679C17.5783 5.31318 16.8264 5.00106 16.042 5H7.95804C7.17368 5.00106 6.42176 5.31318 5.86723 5.8679C5.3127 6.42262 5.00083 7.17464 5.00004 7.959V14C5.00083 14.7844 5.3127 15.5364 5.86723 16.0911C6.42176 16.6458 7.17368 16.9579 7.95804 16.959H10.452L10.424 18.437C10.4217 18.5749 10.4591 18.7106 10.5318 18.8278C10.6045 18.9451 10.7094 19.0388 10.834 19.098L11.294 19.256L14.025 16.959ZM10.8 18.04H10.795H10.8ZM16.042 6.25C16.495 6.25079 16.9291 6.43114 17.2493 6.75149C17.5694 7.07185 17.7495 7.50608 17.75 7.959V14C17.7495 14.4529 17.5694 14.8872 17.2493 15.2075C16.9291 15.5279 16.495 15.7082 16.042 15.709H13.564L11.7 17.283L11.731 15.709H7.95804C7.50512 15.7082 7.07099 15.5279 6.75082 15.2075C6.43065 14.8872 6.25057 14.4529 6.25004 14V7.959C6.25057 7.50608 6.43065 7.07185 6.75082 6.75149C7.07099 6.43114 7.50512 6.25079 7.95804 6.25H16.042Z" fill="#172E35"/>
		</g>
	</>,
	"Comments",
	"0 0 20 20"
);