import {Box, Typography} from "@mui/material";
import {useApi} from "@plumeuk/shapeshift-identity";
import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {useFileBaseUrl} from "@plumeuk/shapeshift-common/hooks";
import {IDashboardBannerResponse} from "../../../types/dashBoardBanner";
import {IFile} from "@plumeuk/shapeshift-types";

interface IStyleProps {
	isBannerImageLoaded: boolean,
	height?: string,
	titleColor?: string
}

const useStyles = makeStyles<IStyleProps>()((theme, {isBannerImageLoaded, height, titleColor}) => ({
	dashboardBanner: {
		height: height,
		width: "100%",
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
		padding: "100px",
		transition: "all 500ms ease",
		[theme.breakpoints.down("sm")]: {
			padding: "40px 30px 100px"
		}
	},
	dashboardBannerImg: {
		objectFit: "cover",
		objectPosition: "left bottom",
		height: "inherit",
		width: "100%",
		position: "absolute",
		left: "0",
		top: "0",
		zIndex: "0",
		transition: "opacity 250ms ease",
		opacity: isBannerImageLoaded ? 1 : 0
	},
	title: {
		fontSize: "70px",
		lineHeight: "81px",
		fontWeight: 800,
		color: titleColor ?? theme.palette.text.primary
	}
}));

export const DashboardBanner: FC = () => {
	const {getUrl} = useFileBaseUrl();
	const [{data: apiData}] = useApi<IDashboardBannerResponse>("/api/dashboard-banner?populate=*");
	const bannerData = apiData?.data?.attributes;
	const [isBannerImageLoaded, setIsBannerImageLoaded] = useState(false)

	function onBackgroundImageLoad(): void {
		if (isBannerImageLoaded) return;
		setTimeout(() => setIsBannerImageLoaded(true), 100);
	}

	const {classes} = useStyles({
		isBannerImageLoaded: isBannerImageLoaded,
		height: bannerData?.height && isBannerImageLoaded ? `${bannerData?.height}px` : "270px",
		titleColor: bannerData?.titleColor
	});

	return (
		<Box className={classes.dashboardBanner}>
			<Box zIndex={1}>
				<Typography className={classes.title}>{bannerData?.title}</Typography>
			</Box>
			<img className={classes.dashboardBannerImg} src={bannerData?.backgroundImage?.data?.attributes.url} loading="eager" onLoad={onBackgroundImageLoad} />
		</Box>
	);
}