import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {DashboardBanner} from "./DashboardBanner/DashboardBanner";
import {Box, Tab, Tabs, CircularProgress, Container} from "@mui/material";
import {redditPalette} from "../../constants";
import {DashboardSideBar} from "./DashboardSideBar/DashboardSideBar";
import {CoursesDataProvider, MemberedGroupsDataProvider} from "@plumeuk/shapeshift-common/providers";
import {useState} from "react";
import DashboardCourses from "./DashboardCourses/DashboardCourses";
import {DashboardSeriesItem} from "./DashboardSeriesItem/DashoardSeriesItem";
import {DashboardNoCourses} from "./DashboardNoCourses/DashboardNoCourses"

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		[theme.breakpoints.up("lg")]:{
			"& [class*='menuIcon']": {
				display: "none"
			},
			"[class*='logoContainer']": {
				"& img": {
					left: "25px"
				}
			}
		},
		"& [class*='contentContainer']": {
			padding: "0"
		}
	},
	contentContainer: {
		minHeight: "100%",
		backgroundColor: redditPalette.white,
		padding: "25px"
	},
	tabs: {
		border: "none",
		position: "relative",
		top: "-55px",
		"& .MuiTab-root": {
			borderTopLeftRadius: "10px",
			borderTopRightRadius: "10px",
			padding: "20px 45px 15px 45px",
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.background.paper

		},
		"& .Mui-selected": {
			color: redditPalette.white,
			backgroundColor: theme.palette.secondary.main
		},
		[theme.breakpoints.down("sm")]: {
			".MuiTabs-flexContainer": {
				gap: "10px"
			},
			"& .MuiTab-root": {
				padding: "20px 40px 15px 40px"
			}
		}
	},
	centerWrapper: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		flexDirection: "column"
	}
}));

export type DashboardTab = "progress" | "complete"

export const Dashboard: React.FC = () => {
	const {classes} = useStyles();
	const [tabValue, setTabValue] = useState<DashboardTab>("progress");

	const handleTabChange = (_event: React.SyntheticEvent, newValue: DashboardTab): void => {
		setTabValue(newValue);
	};

	return (
		<PageBase contentWidth="100%" className={classes.pageBase} disableSideBar sideBarContent={<DashboardSideBar/>}>
			<DashboardBanner />
			<Box className={classes.contentContainer}>
				<Container>
					<Tabs
						className={classes.tabs}
						value={tabValue}
						onChange={handleTabChange}
						TabIndicatorProps={{
							style: {display: "none"}
						}}
					>
						<Tab label="In progress" id={`tab_${tabValue}`} value="progress"/>
						<Tab label="Completed" id={`tab_${tabValue}`} value="complete" />
					</Tabs>
					<MemberedGroupsDataProvider>
						{({groups}) =>
							<CoursesDataProvider showProgress>
								{({courses, setCourses}) => {
									const filteredCourses = courses?.filter(e => (e.percentCompleted === 100) === (tabValue === "complete"));

									if(!groups || !filteredCourses)
										return <div className={classes.centerWrapper}><CircularProgress color="secondary" /></div>

									const groupElements = groups.map(group => {
										const groupCourses = courses?.filter(e =>
											e.groupId === group.id
											&& (e.percentCompleted === 100) === (tabValue === "complete")
										) ?? [];
										return groupCourses.length === 0
											? null
											: <DashboardSeriesItem completedView={tabValue === "complete"} group={group} courses={groupCourses} />
									}).filter(e => !!e)

									return (
										<>
											{groupElements}
											{filteredCourses && filteredCourses.length > 0 && <DashboardCourses courses={filteredCourses} setCourses={setCourses} />}
											{(filteredCourses && filteredCourses.length === 0 && groupElements.length === 0) && <DashboardNoCourses tab={tabValue} />}
										</>
									)}}
							</CoursesDataProvider>
						}
					</MemberedGroupsDataProvider>
				</Container>
			</Box>
		</PageBase>
	);
}