import {QuizDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {QuizRouter} from "./QuizRouter";

const useStyles = makeStyles()((_theme) => ({
	centeredContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center"
	}
}));

export const QuizPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: quizSlug} = useParams();
	return (
		<CenteredContainer className={classes.centeredContainer}>
			<QuizDataProvider courseSlug={courseSlug} quizSlug={quizSlug}>
				{({quiz}) => <QuizRouter quiz={quiz}/>}
			</QuizDataProvider>
		</CenteredContainer>
	);
}