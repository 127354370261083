import {QuizQuestionListContainer as SSQuizQuestionContainer} from "@plumeuk/shapeshift-common/quiz";
import {IQuizQuestion, IQuizAnswer} from "@plumeuk/shapeshift-types";
import {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

interface IProps {
	questions?: IQuizQuestion[],
	onSubmit: (answers: IQuizAnswer[]) => void,
	submitResponseResultId?: string
}

export const QuizQuestionContainer: FC<IProps> = ({questions, onSubmit, submitResponseResultId}) => {
	const navigate = useNavigate();


	useEffect(() => {
		if(submitResponseResultId)
			navigate("../complete/" + submitResponseResultId)
	}, [submitResponseResultId])


	return (
		<SSQuizQuestionContainer
			questions={questions}
			onSubmit={answers => {onSubmit(answers)}}
		/>
	);
}