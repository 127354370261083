import {makeStyles} from "tss-react/mui";
import {FC} from "react";
import {ReactMarkdownProps} from "react-markdown/lib/complex-types";
import {Platform} from "./embedLink";

type Props = Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, "ref">
& ReactMarkdownProps
& {platform: Platform};

const useStyles = makeStyles()((theme) => ({
	iframe: {
		border: "1px solid lightgray",
		borderRadius: "4px",
		width: "100%",
		aspectRatio: "1.8/1", // youtube video ratio
		height: "auto"
	}
}));

function getIframeLink(platform: Platform, href: string): string {
	if (platform === "google") {
		return href.replace(/(\/edit.*)|(\/view.*)/, "/preview")
	}

	if (platform === "youtube") {
		const url = new URL(href);
		const searchParams = url.searchParams;
		return `${url.origin}/embed/${searchParams.get("v")}`
	}

	if (platform === "canva") {
		return href.replace(/(\/edit.*)|(\/view.*)/, "/view?embed")
	}

	if (platform === "typeform") {
		if (!href.includes("/to/")) {
			// eslint-disable-next-line no-console
			console.error(`For embedding please use the typeform link that ends in "/to/{formcode}" instead of using the form link you have pasted ${href}`)
			return ""
		}
		return href
	}

	return href
}

export const EmbedLinkAsIframe: FC<Props> = ({children, platform, ...props}) => {
	const {classes, cx} = useStyles();
	if (!props.href) return <></>

	const iframeSrc = getIframeLink(platform, props.href);
	if (!iframeSrc) return <></>

	return (
		<iframe src={iframeSrc} className={cx(classes.iframe, platform)} frameBorder="0" allowFullScreen={true}></iframe>
	)
};

export default EmbedLinkAsIframe;