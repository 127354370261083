import {FC} from "react"
import {makeStyles} from "tss-react/mui";
import {LibraryCarousel as SSLibraryCarousel, LibraryItem, LibraryCarouselProps} from "@plumeuk/shapeshift-common/library";
import {redditPalette} from "../../constants";
import {LibraryCarouselItem} from "../../components/libraryCarousel/libraryCarouselItem";

const useStyles = makeStyles()((theme) => ({
	libraryItem: {
		border: `1px solid ${redditPalette.grey300}`,
		["[class*='tagContainer-tagContainer']"]: {
			flexWrap: "wrap",
			justifyContent: "flex-end",
			padding: "15px",
			gap: "10px",
			"& button": {
				marginRight: 0,
				borderRadius: "500px",
				padding: "0 20px",
				minHeight: "30px",
				background: theme.palette.secondary.main,
				border: "none"
			},
			"& img": {
				filter: "invert(1)"
			}
		},
		["[class*='libraryItemProgressionActionBar']"]: {
			padding: "10px 30px"
		},
		["[class*='imageContainer']"]: {
			height: "220px"
		}
	}
}));


export const LibraryCarousel:FC<LibraryCarouselProps> = (props) => {
	const {cx, classes} = useStyles();
	return (
		<SSLibraryCarousel
			{...props}
			componentOverrides={{item: (baseProps) =>
				<LibraryItem
					{...baseProps}
					className={cx(baseProps.className, classes.libraryItem)}
					enableProgressionBar
					componentOverrides={{
						content: <LibraryCarouselItem item={baseProps.item} />
					}}
				/>}
			}
		/>
	)
}