import {ICourse} from "@plumeuk/shapeshift-types"
import React, {useCallback} from "react"
import {useNavigate} from "react-router-dom"
import CourseItem from "../../../components/courseItem/courseItem"
import {ICoursesReturnProp} from "@plumeuk/shapeshift-common/hooks"

export interface IProps {
	courses: ICourse[],
	setCourses: ICoursesReturnProp["setCourses"]
}

const DashboardCourses: React.FC<IProps> = ({courses, setCourses}) => {
	const navigate = useNavigate();
	const onUnenroll = useCallback((courseId?: number): void => {
		if (courseId) {
			setCourses(courses.filter((course)=> course.id !== courseId));
		}
	}, [courses])

	return (
		<div>
			{courses.filter(e => !e.groupId).map(course =>
				<CourseItem
					isEnrolled
					key={course.id}
					course={course}
					onUnenroll={() => onUnenroll(course.id)}
					onGoToCourseAction={() => navigate("/learn/course/" + course.slug)}
				/>
			)}
		</div>
	)
}

export default DashboardCourses;