import {VideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {ILesson} from "@plumeuk/shapeshift-types";
import ReactPlayer, {ReactPlayerProps} from "react-player";
import {useParams} from "react-router-dom";
import {API, AuthContext, hitApi} from "@plumeuk/shapeshift-identity";
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";

const useStyles = makeStyles()((theme) => ({
	videoPlayerBackdrop: {
		background: "black",
		width: "100%",
		boxSizing: "border-box",
		left: 0,
		top: 0,
		right: "0px",
		position: "absolute",
		height: "550px",
		[theme.breakpoints.down("sm")]: {
			height: "250px"
		}
	},
	videoPlayer: {
		zIndex: 5,
		background: "black",
		position: "absolute",
		opacity: 0,
		left: 0,
		top: -10,
		transition: "opacity .5s ease-in",
		right: "0px"
	},
	videoPlayerSpacer: {
		height: "550px",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			height: "280px"
		}
	}
}));

interface IPropsCustom {
	lesson?: ILesson
}

export type IProps = Omit<ReactPlayerProps, "url"> & IPropsCustom;

export const LessonVideoPlayer = forwardRef<VideoPlayerRef, IProps>(function LessonVideoPlayer({lesson}, ref) {
	const {jwt, config} = useContext(AuthContext);
	const internalRef = useRef<VideoPlayerRef>(null);
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const mq = useMq()
	const [videoReady, setVideoReady] = useState(false);
	const lastProgress = useRef<{progressTime: number, epoch: number}>();
	const videoPlayed = useRef(false);
	const [videoPlaying, setVideoPlaying] = useState(false);
	const [resumeConfirmOpen, setResumeConfirmOpen] = useState(false);

	useImperativeHandle(ref, () => {
		if(internalRef.current) {
			return internalRef.current
		}
		return {};
	}, [internalRef.current]);

	const getVideoTimestamp = (): number | undefined => {
		if (!internalRef || !internalRef.current) return 0;
		if(internalRef.current.react)
			return Math.round(internalRef.current.react.getCurrentTime() ?? 0);
		if(internalRef.current.mux)
			return Math.round(internalRef.current.mux.currentTime ?? 0);
	};

	const updateLastProgress = (): void => {
		const epoch = Math.round(new Date().getTime() / 1000);
		const ts = getVideoTimestamp();
		if(ts)
			lastProgress.current = ({progressTime: ts, epoch})
	}

	const saveVideoTime = useCallback(() => {
		if(!courseSlug || !lesson || !videoPlayed.current)
			return;

		let videoTime;
		if( lastProgress?.current){
			videoTime = lastProgress.current.progressTime;

			if(videoPlaying){
				const epoch = Math.round(new Date().getTime() / 1000);
				const epochDiff = epoch - lastProgress.current.epoch;
				videoTime += epochDiff;
			}
		}
		if(!videoTime)
			return;

		hitApi(
			{
				url: API.saveLessonVideoTime(courseSlug, lesson.slug),
				data: {time: videoTime},
				method: "PUT"
			},
			{baseApiUrl: config?.baseApiUrl ?? ""},
			jwt
		)
	}, [courseSlug, lesson, lastProgress, jwt])

	useEffect(() => {
		if(!lesson?.videoTime || videoPlayed.current || !videoPlaying)
			return;

		setResumeConfirmOpen(true);
		setVideoPlaying(false);
	}, [lesson, videoPlayed, videoPlaying]);

	useEffect(() => {
		if(videoPlaying)
			videoPlayed.current = true;
	}, [videoPlaying]);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
			saveVideoTime();
			videoPlayed.current = false;
			event.preventDefault();
			event.returnValue = ""; // For older browsers
		};

		//catch page refresh, close or external navigation
		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);

			//catch for component unmount, i.e internal navigation
			saveVideoTime();
			videoPlayed.current = false;
		};
	}, []);

	const handleResumeVideo = (): void => {
		setResumeConfirmOpen(false);
		if(internalRef && internalRef.current) {
			if(internalRef.current.react)
				internalRef.current.react.seekTo(lesson?.videoTime ?? 0, "seconds");
			if(internalRef.current.mux)
				internalRef.current.mux.fastSeek(lesson?.videoTime ?? 0);
			setVideoPlaying(true);
		}
	}

	const handleRestartVideo = (): void => {
		setResumeConfirmOpen(false);
		setVideoPlaying(true);
	}

	if(!lesson?.videoUrl) return <></>

	return (
		<>
			<div className={classes.videoPlayerBackdrop}>
				<VideoPlayer
					ref={internalRef}
					onReady={() => setVideoReady(true)}
					onPlay={() => {updateLastProgress(); setVideoPlaying(true)}}
					playing={videoPlaying}
					onPause={() => setVideoPlaying(false)}
					onEnded={() => lastProgress.current = (undefined)}
					className={classes.videoPlayer}
					style={{opacity: videoReady ? 1 : 0}}
					url={lesson.videoUrl}
					controls={true}
					height={mq.mobile ? "250px" : "550px"}
				/>
			</div>
			<div className={classes.videoPlayerSpacer}/>
			<Dialog
				open={resumeConfirmOpen}
				onClose={() => setResumeConfirmOpen(false)}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					Do you want to resume video at last position?
				</DialogTitle>
				<DialogActions>
					<Button autoFocus onClick={() => handleRestartVideo()}>
						Restart
					</Button>
					<Button onClick={handleResumeVideo} autoFocus>
						Resume
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
});