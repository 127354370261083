import {Box, Button, Typography} from "@mui/material";
import {useApi} from "@plumeuk/shapeshift-identity";
import {FC, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {WYSIWYG} from "../../components/WYSIWYG/WYSIWYG";
import {useFileBaseUrl, useLibraryCourse} from "@plumeuk/shapeshift-common/hooks";
import {redditPalette} from "../../constants";
import {Link} from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {LibraryBannerVideo} from "./LibraryBannerVideo";
import {LibraryBannerImage} from "./LibraryBannerImage";
import {ILibraryBannerResponse} from "../../types/courseLibraryBanner";

interface IStyleProps {
	backgroundUrl?: string
}

const useStyles = makeStyles<IStyleProps>()((theme, {backgroundUrl}) => ({
	libraryBanner: {
		position: "relative",
		width: "100vw",
		padding: "0",
		backgroundColor: redditPalette.bg
	},
	contentContainer: {
		minHeight: "500px",
		paddingBottom: "110px !important",
		[theme.breakpoints.up("sm")]: {
			width: "100%"
		},
		display: "flex",
		flexDirection: "row",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column-reverse",
			paddingBottom: "53px !important"
		}
	},
	leftContentContainer: {
		width: "50%",
		position: "relative",
		padding: "100px 100px 0 100px",
		boxSizing: "border-box",
		[theme.breakpoints.down("md")]: {
			padding: "20px",
			width: "100%"
		}
	},
	rightContentContainer: {
		width: "50%",
		padding: backgroundUrl ? "80px 100px 90px 0" : "80px 100px 0px 0",
		boxSizing: "border-box",
		backgroundImage: backgroundUrl && `url('${backgroundUrl}')`,
		backgroundPosition: "top right",
		backgroundSize: "205% 114%",
		backgroundRepeat: "no-repeat",

		[theme.breakpoints.down("md")]: {
			padding: "20px",
			width: "100%"
		}
	},
	title: {
		fontSize: "70px",
		lineHeight: "81px",
		fontWeight: 800,
		color: redditPalette.darkGrey,
		[theme.breakpoints.down("md")]: {
			fontSize: "50px",
			lineHeight: "60px"
		}
	},
	content: {
		color: redditPalette.grey700,
		"& p": {
			fontSize: "22px",
			lineHeight: "37px"
		}
	},
	button: {
		backgroundColor: redditPalette.white,
		textAlign: "center",
		"&:hover": {
			opacity: 0.85
		},
		"& svg": {
			marginRight: "10px",
			fill: redditPalette.white,
			width: "24px",
			marginBottom: "-3px",
			[theme.breakpoints.down("sm")]: {
				width: "21px"
			}
		}
	},
	buttonPrimary: {
		backgroundColor: `${redditPalette.orange}!important`,
		color: redditPalette.white,
		borderColor: `${redditPalette.orange}!important`
	},
	buttonSecondary: {
		backgroundColor: `${redditPalette.bg}!important`,
		color: `${redditPalette.darkGrey}!important`,
		borderColor:  `${redditPalette.orange}!important`
	},
	courseInfoContainer: {
		[theme.breakpoints.down("md")]: {
			display: "flex",
			flexDirection: "column-reverse"
		}
	},
	libraryBannerButtons: {
		display: "flex",
		gap:8,
		flexWrap: "wrap"

	},
	libraryBannerButton: {
		display: "inline-block",
		marginTop: "5px",
		maxWidth: "250px"
	},
	libraryBannerInfoTitle: {
		color: redditPalette.darkGrey,
		fontSize: "21px",
		lineHeight: "27px",
		fontWeight: 700,
		margin: "30px 0"
	},
	libraryBannerInfoContainer: {
		maxWidth: "400px",
		"b": {
			color: redditPalette.darkGrey
		}
	},
	libraryBannerInfo: {
		display: "grid",
		gridTemplateColumns: "auto auto",
		gap: "20px",
		marginTop: "20px",
		whiteSpace: "pre-wrap",
		"& > *": {
			display: "flex",
			alignItems: "center"
		},
		"& svg": {
			width: "22px",
			marginRight: "13px",
			fill: redditPalette.orange,
			display: "inline-block",
			verticalAlign: "middle"
		},
		[theme.breakpoints.down("sm")]: {
			"&": {
				gridTemplateColumns: "1fr",
				gap: "10px"
			},
			"& svg": {
				width: "20px",
				marginRight: "10px"
			}
		}
	},
	navIcon: {
		maxWidth: "16px",
		display: "inline-block",
		filter: "brightness(0) invert(1)",
		paddingRight: "10px"
	}
}));

export const LibraryBanner: FC = () => {
	const {getUrl} = useFileBaseUrl();
	const [{data: apiData}] = useApi<ILibraryBannerResponse>({url: "/api/course-library-banner", data: {populate: "*"}});
	const {course: featuredCourse, getCourse} = useLibraryCourse()
	const bannerData = apiData;
	const backgroundUrl = bannerData?.backgroundImage?.url;
	const image = bannerData?.featuredImage?.url

	const {classes, cx} = useStyles({
		backgroundUrl: backgroundUrl ? getUrl(backgroundUrl) : undefined
	});

	useEffect(() => {
		const slug = apiData?.featuredCourse?.slug
		if(!slug)
			return

		getCourse(slug)
	}, [apiData])

	//const dateDay = bannerData?.date?.toLocaleDateString("en-UK", {weekday: "long"});
	//const dateTime = bannerData?.date?.toLocaleTimeString("en-GB", {hour: "numeric", minute: "numeric"});

	return (
		<Box className={classes.libraryBanner}>
			<Box className={classes.contentContainer}>
				<Box className={classes.leftContentContainer}>
					<Typography className={classes.title}>{bannerData?.title}</Typography>
					<WYSIWYG className={classes.content}>
						{bannerData?.content ?? ""}
					</WYSIWYG>
					<Box className={classes.courseInfoContainer}>
						<Box className={classes.libraryBannerButtons}>
							{/* <Button className={cx(classes.button, classes.buttonPrimary, classes.libraryBannerButton)}>
								<IconCalendar />
									Register
							</Button> */}
							{featuredCourse && !featuredCourse.enrolled &&
								<Button
									component={Link}
									className={cx(classes.button, classes.buttonPrimary, classes.libraryBannerButton)}
									to={`/discover/${featuredCourse.slug}`}
								>
									<img className={classes.navIcon} src="/icons/learn.svg"/>
									Preview Course
								</Button>}
							{featuredCourse?.enrolled &&
								<Button
									component={Link}
									to={`/learn/course/${featuredCourse.slug}`}
									className={cx(classes.button, classes.buttonPrimary, classes.libraryBannerButton)}
								>
									<ArrowForwardIcon fontSize="small"/>
									Resume
								</Button>}
							{bannerData?.learnMoreLink &&
								<Button
									component={Link}
									to={bannerData.learnMoreLink}
									className={cx(classes.button, classes.buttonSecondary, classes.libraryBannerButton)}
								>
									Learn More
								</Button>}
						</Box>
						{/* <Box className={classes.libraryBannerInfoContainer}>
							<Typography className={classes.libraryBannerInfoTitle}>About this course</Typography>
							<Box className={classes.libraryBannerInfo}>
								<Box>
									<IconCalendar />
									<b>Date: {dateDay}: </b>{dateTime}
								</Box>
								<Box>
									<IconClock />
									<b>Duration: </b>{bannerData?.duration}
								</Box>
								<Box>
									<IconBook />
									<b>Subject: </b>{bannerData?.subject}
								</Box>
								<Box>
									<IconCollection />
									<b>Modules: </b>{bannerData?.modules}
								</Box>
							</Box>
						</Box> */}
					</Box>
				</Box>
				<Box className={classes.rightContentContainer}>
					{!image && bannerData?.videoUrl && <LibraryBannerVideo videoUrl={bannerData?.videoUrl} />}
					{image && <LibraryBannerImage src={image} />}
				</Box>
			</Box>
		</Box>
	);
}