import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {ModuleCompleteButton, ModuleNotAvailable, ModuleTabs, ModuleTab} from "@plumeuk/shapeshift-common/module";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {NotesSection} from "@plumeuk/shapeshift-common/notes";
import {CommentsSection} from "@plumeuk/shapeshift-common/comments";
import {Links} from "@plumeuk/shapeshift-common/links";
import {Files} from "../../../components/files/files";
import {LessonDataProvider} from "@plumeuk/shapeshift-common/providers";
import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";
import {INotificationSeverity, APIState, NotificationContext} from "@plumeuk/shapeshift-identity";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {WYSIWYG} from "../../../components/WYSIWYG/WYSIWYG";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {LessonVideoPlayer} from "./LessonVideoPlayer";
import InfoIcon from "@mui/icons-material/Info";
import {useLesson, useSetModuleCompletion} from "@plumeuk/shapeshift-common/hooks";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {getNextLinearModule} from "@plumeuk/shapeshift-common/common";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {redditPalette} from "../../../constants";


const useStyles = makeStyles()((theme) => ({
	lessonPage: {
		paddingTop: "50px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	title:{
		marginBottom: "40px",
		marginTop: "30px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "30px"
		}
	},
	centeredContainer: {
		paddingBottom: "100px"
	},
	lessonCompleteBtn: {
		float: "right",
		background: theme.palette.secondary.main,
		border: "none",
		"&:hover": {
			background: theme.palette.secondary.main
		}
	},
	lessonUnCompleteBtn: {
		float: "right"
	},
	content: {
		marginBottom: "40px"
	},
	contentImage: {
		maxWidth: "100%"
	},
	fileTitle: {
		fontWeight: 700,
		margin: "20px 0"
	},
	files: {
		marginBottom: "40px"
	},
	links: {
		marginBottom: "40px"
	},
	nextBtn: {
		position: "absolute",
		[theme.breakpoints.down("sm")]: {
			margin: 0,
			borderRadius: "0",
			left: 0,
			top: "240px",
			padding: "14px 0",
			right: "0px",
			"& svg": {
				fontSize: "20px"
			}
		},
		[theme.breakpoints.up("sm")]: {
			zIndex: 10,
			background: "#fff",
			color: theme.palette.secondary.main,
			right: "-160px",
			fontSize: "22px",
			opacity: 0.7,
			top: "200px",
			borderRadius: 100,
			padding: "22px 150px 22px 22px",
			transition: "all 0.2s ease-in-out",
			"&:hover": {
				opacity: 1,
				right: "-140px",
				color: theme.palette.secondary.main,
				background: "#fff"
			},
			"& span": {
				display: "none"
			},
			"& svg": {
				"& path": {

				},
				fontSize: "48px"
			}
		}
	},
	tabs: {
		marginBottom: "20px"
	},
	tab: {
		color: "#A8A8A8",
		fontSize: "18px"
	},
	activeTab: {
		color: "#2A2A2F !important"
	},
	messagebanner: {
		margin: "30px 10px",
		"& p": {
			color: redditPalette.grey
		},
		"& svg": {
			position: "relative",
			top: "6px",
			marginRight: "5px",
			"& path": {
				fill: redditPalette.grey400
			}
		}
	}
}));

export const LessonPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {curriculumState: curriculum, curriculumDispatch} = useContext(CurriculumContext);
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();
	const [tabSelected, setTab] = useState<ModuleTab>("overview");
	const {setComplete, apiResult: setCompleteApiResult} = useSetModuleCompletion();
	const {lesson, getLesson} = useLesson(courseSlug, lessonSlug);
	const [nextModule, setNextModule] = useState<ICourseCurriculumModule>();

	useEffect(() => {
		getLesson(courseSlug, lessonSlug)
	}, [courseSlug, lessonSlug])

	useEffect(() => {
		if(courseSlug && lessonSlug && curriculum)
			setNextModule(getNextLinearModule(curriculum, {slug: lessonSlug, type: "lesson"}))
	}, [courseSlug, lessonSlug, curriculum])

	const handleCompletion = useCallback((e: APIState<IModuleEnrollment>): void => {
		if(e.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000);
		}
		else if(!e.isLoading && !e.isError && e.statusCode === 200){
			const status = !!e?.data?.complete;
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "lesson", slug: lessonSlug as string}, status})

			if(e?.data?.complete){
				notify(null, "Lesson completed", INotificationSeverity.success, 5000);
				navigate("/learn/course/" + courseSlug + "/next")
			}
			else {
				notify("Set to incomplete", "Lesson Updated", INotificationSeverity.success, 5000);
			}
		}
	}, [curriculumDispatch, lessonSlug])

	const handleNextLesson = (): void => {
		if(!curriculum || !lessonSlug)
			return;

		if(!nextModule)
			navigate(`/learn/course/${courseSlug}/complete`)
		else
			navigate(`/learn/course/${courseSlug}/${nextModule?.type}/${nextModule?.slug}`)
	}

	//set module to complete if viewed and progression tracking off.
	//this means the default nav for get next available incomplete will not continue to navigate to module
	useEffect(() => {
		if(lesson?.progression === false && courseSlug && lessonSlug)
			setComplete(courseSlug, lessonSlug, "lesson", true)
	}, [lesson])

	const videoRef = useRef<VideoPlayerRef>(null);

	return (
		<Box className={classes.lessonPage}>
			<LessonDataProvider courseSlug={courseSlug} lessonSlug={lessonSlug} curriculum={curriculum} data={lesson}>
				{({lesson, apiResult}) => <>
					{apiResult.statusCode === 200 && <>
						{lesson?.videoUrl && <>
							<LessonVideoPlayer ref={videoRef} lesson={lesson}/>
						</>}
						<CenteredContainer className={classes.centeredContainer}>
							<ModuleTabs
								moduleTabs={[{label: "Overview", value: "overview"}, {label: "Comments", value: "comments"}, {label: "Notes", value: "notes"}]}
								tabSelected={tabSelected}
								onChange={tab => setTab(tab)} className={classes.tabs}
							/>
							{(tabSelected === "overview") && <>
								<PageTitle className={classes.title} title={lesson?.title} subtitle={lesson?.subtitle} />
								<WYSIWYG className={classes.content}>
									{lesson?.content ?? ""}
								</WYSIWYG>

								{lesson?.files && lesson.files.length > 0 && <>
									<Typography className={classes.fileTitle} variant="h5">Files</Typography>
									<Files className={classes.files} files={lesson.files}/>
								</>}

								{lesson?.links && lesson.links.length > 0 && <>
									<Typography className={classes.fileTitle} variant="h5">Links</Typography>
									<Links className={classes.links} links={lesson.links}/>
								</>}

								{courseSlug && lesson?.progression !== false && lesson?.slug && !lesson?.complete &&
									<ModuleCompleteButton
										courseSlug={courseSlug}
										moduleSlug={lesson.slug}
										type="lesson"
										className={classes.lessonCompleteBtn}
										onApiUpdate={handleCompletion}
									>{() => <>Next <ArrowForwardIcon sx={{position: "relative", right: "-14px", fontSize: 30, margin: 0, padding: 0}}/></>}</ModuleCompleteButton>
								}

								{(lesson?.complete || lesson?.progression === false) &&
									<Button className={classes.lessonCompleteBtn} onClick={() => handleNextLesson()}>
										Next <ArrowForwardIcon sx={{position: "relative", right: "-14px", fontSize: 30, margin: 0, padding: 0}}/>
									</Button>}
							</>}
							{(tabSelected === "comments") && <>
								<Box className={classes.messagebanner}><Typography><InfoIcon /> Type your public comments here</Typography></Box>
								<CommentsSection courseSlug={courseSlug} moduleType={"lesson"} moduleSlug={lessonSlug}/>
							</>}
							{(tabSelected === "notes" ) && <>
								<Box className={classes.messagebanner}><Typography><InfoIcon /> Type your private notes here</Typography></Box>
								<NotesSection disableTimeStamp={!lesson?.videoUrl} courseSlug={courseSlug} moduleType="lesson" moduleSlug={lessonSlug} videoRef={videoRef} />
							</>}
						</CenteredContainer>
					</>}
					{apiResult.statusCode === 403 &&
						<ModuleNotAvailable>
							{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
						</ModuleNotAvailable>
					}
				</>}
			</LessonDataProvider>
		</Box>
	);
}