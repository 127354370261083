import {MarkQuizActionProvider, QuizQuestionsDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../../components/pageBase/centeredContainer";
import {QuizQuestionContainer} from "./QuizQuestion/QuizQuestionContainer";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {WYSIWYG} from "../../../../components/WYSIWYG/WYSIWYG";
import {IQuiz} from "@plumeuk/shapeshift-types";
import {redditPalette} from "../../../../constants";

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "left",
		marginTop: "40px",
		"& [class*='questionContainer']":{
			"& h3": {
				fontStyle: "normal",
				fontWeight: 800,
				fontSize: "26px",
				lineHeight: "30px",
				[theme.breakpoints.down("sm")]: {
					marginTop: "7px"
				}
			}
		},
		"& [class*='questionHint'] [class*='bodyContainer'] [class*='body']": {
			color: theme.palette.common.black,
			opacity: 0.5
		},
		"& [class*='optionContainer'] [class*='questionOption']": {
			borderRadius: "78px",
			borderWidth: "1px",
			"& [class*='optionId']": {
				fontWeight: 800,
				borderWidth: "1px"
			}
		},
		"& [class*='questionContainer']:not(:first-child)": {
			borderTop: `1px solid ${redditPalette.grey100}`,
			marginTop: "46px"
		},
		"& [class*='questionCounter']": {
			color: "#7A9097",
			fontSize: "16px",
			fontWeight: "700",
			lineHeight: "35px",
			textTransform: "uppercase",
			[theme.breakpoints.down("sm")]: {
				fontSize:  "14px"
			}
		},
		"& [class*='answerCounter']": {
			fontSize: "15px",
			fontWeight: "600",
			lineHeight: "18px",
			padding: "11px 20px",
			textAlign:  "center",
			background: redditPalette.grey100,
			color: redditPalette.textPrimary,
			[theme.breakpoints.down("sm")]: {
				fontSize:  "14px",
				padding: "10px 16px"
			}
		}
	},
	title: {
		marginBottom: "18px",
		"& h2": {
			fontSize: "58px"
		},
		"& h5": {
			fontSize: "24px",
			lineHeight: "46px",
			marginTop: "0px !important"
		},
		[theme.breakpoints.down("sm")]: {
			marginBottom: "10px",
			"& h2": {
				fontSize: "42px"
			},
			"& h5": {
				fontSize: "20px",
				marginTop: "-10px !important"
			}
		}
	},
	content: {
		marginBottom: "10px",
		paddingBottom: "20px",
		borderBottom: `${redditPalette.grey200} solid 1px`,
		"& *:first-child": {
			marginTop: "0px",
			paddingTop: "0px"
		},
		"& p": {
			color: redditPalette.textPrimary,
			fontSize: "18px",
			fontWeight: 400,
			lineHeight: "160%",
			[theme.breakpoints.down("sm")]: {
				fontSize: "17px"
			}
		}
	}
}));

interface IProps {
	quiz?: IQuiz
}

export const QuizQuestions: FC<IProps> = ({quiz}) => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: quizSlug} = useParams();

	return (
		<CenteredContainer className={classes.centeredContainer}>
			<PageTitle className={classes.title} title={quiz?.title} subtitle={quiz?.subtitle} />
			<WYSIWYG className={classes.content}>
				{quiz?.content ?? ""}
			</WYSIWYG>
			<MarkQuizActionProvider>
				{({markAnswers, response}) =>
					<QuizQuestionsDataProvider courseSlug={courseSlug} quizSlug={quizSlug}>
						{({quizQuestions}) =>
							<QuizQuestionContainer
								questions={quizQuestions}
								onSubmit={(answers) => markAnswers(courseSlug as string, quizSlug as string, answers)}
								submitResponseResultId={response}
							/>
						}
					</QuizQuestionsDataProvider>
				}
			</MarkQuizActionProvider>
		</CenteredContainer>
	);
}