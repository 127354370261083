import {Box, Button, Typography} from "@mui/material";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {MarkedQuestion, MarkedQuestionsSummary} from "@plumeuk/shapeshift-common/quiz";
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {useNavigate, useParams} from "react-router-dom";
import {IMarkedQuizAnswers, IQuiz} from "@plumeuk/shapeshift-types";
import {redditPalette} from "../../../../../constants";

const useStyles = makeStyles()((theme) => ({
	quizResult: {
		marginTop: "40px",
		textAlign: "left",
		[theme.breakpoints.down("sm")]: {
			marginTop: "15px"
		}
	},
	markedAnswer: {
		paddingTop: "55px",
		marginBottom: "80px",

		"&:not(:first-child)": {
			borderTop: `1px solid ${redditPalette.grey100}`,
			marginTop: "46px"
		},
		"& [class*='label']": {
			color: "#7A9097",
			fontSize: "16px",
			fontWeight: "700",
			lineHeight: "35px",
			textTransform: "uppercase",
			[theme.breakpoints.down("sm")]: {
				fontSize:  "14px"
			}
		},
		"& h3": {
			fontStyle: "normal",
			fontWeight: 800,
			fontSize: "26px",
			lineHeight: "30px",
			[theme.breakpoints.down("sm")]: {
				marginTop: "7px"
			}
		},
		[theme.breakpoints.down("sm")]: {
			paddingTop: "37px",
			marginBottom: "45px"
		}
	},
	label: {
		lineHeight: 0,
		color: "#A8A8A8"
	},
	title: {
		margin: "60px 0 20px 0"
	},
	subTitle: {
		marginBottom: "40px",
		color: "#A8A8A8"
	},
	nextBtn: {
		height: "52px"
	}
}));

interface IProps {
	quiz?: IQuiz,
	markedAnswers: IMarkedQuizAnswers
}

export const QuizResultBreakdown: FC<IProps> = ({quiz, markedAnswers}) => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const mq = useMq();
	const navigate = useNavigate();

	return (
		<Box className={classes.quizResult}>
			<MarkedQuestionsSummary markedAnswers={markedAnswers}/>
			{!mq.mobile ? <>
				<Typography variant="h1" className={classes.title}>{quiz?.title}</Typography>
				<Typography variant="h3" className={classes.subTitle}>Pass by scoring {markedAnswers.requiredScore}%</Typography>
			</> : <Box sx={{marginBottom: "40px"}}/>}
			{markedAnswers?.markedAnswers?.map((answer, i) => (
				<Box key={`marked-answer-${i}`} className={classes.markedAnswer}>
					<Typography className={classes.label}>Question {i+1} of {markedAnswers.markedAnswers.length}</Typography>
					<MarkedQuestion markedAnswer={answer}/>
				</Box>
			))}
			<Button
				onClick={() => markedAnswers.pass ? navigate("/learn/course/" + courseSlug + "/next") : navigate("../active")}
				className={classes.nextBtn}
			>
				{markedAnswers.pass ? "Next module" : "Retake"}
			</Button>
		</Box>
	);
}