import {FC, useCallback, useContext} from "react"
import {Box} from "@mui/material"
import {makeStyles} from "tss-react/mui";
import {redditPalette} from "../../constants";
import {LibraryDataProvider} from "@plumeuk/shapeshift-common/providers";
import {LibraryCarousel} from "../libraryCarousel/libraryCarousel";
import {ILibraryItem, isILibraryCourse, isILibraryGroup} from "@plumeuk/shapeshift-types";
import {useNavigate} from "react-router-dom";
import {PageBaseContext} from "../../contexts/pageBaseContext";

const useStyles = makeStyles()((_theme) => ({
	reccomendedContainer: {
		width: "100%",
		background: redditPalette.white,
		padding: "100px 0"
	},
	recommended: {
		width: "100%",
		maxWidth: "1300px",
		margin: "auto"
	}
}));


export const Reccomended:FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const [pageBaseContext] = useContext(PageBaseContext)

	const handleGoToItemAction = useCallback((e: ILibraryItem) => {
		const element = pageBaseContext.contentContainerRef?.current;
		if (element) {
			(element as any).scrollTop = 0;
		}
		if(isILibraryCourse(e))
			navigate(e.enrolled ? `/learn/course/${e.slug}` : `/discover/${e.slug}`)

		else if(isILibraryGroup(e))
			navigate(e.enrolled ? `/learn/course/${e.slug}` : `/discover/${e.slug}`)
	}, [pageBaseContext])


	return (
		<Box className={classes.reccomendedContainer}>
			<Box className={classes.recommended}>
				<LibraryDataProvider search={{searchTerm: ""}}>
					{({items}) => <>
						<LibraryCarousel
							carousel={{id: 1, items: items ?? [], title: "You may also like"}}
							onGoToItemAction={handleGoToItemAction}
						/>
					</>}
				</LibraryDataProvider>
			</Box>
		</Box>
	)
}