import {createSvgIcon} from "@plumeuk/shapeshift-common/icon";

export const IconFileVideo = createSvgIcon(
	(
		<>
			<path xmlns="http://www.w3.org/2000/svg" d="M0 35C0 6.1775 6.1775 0 35 0C63.8225 0 70 6.1775 70 35C70 63.8225 63.8225 70 35 70C6.1775 70 0 63.8225 0 35Z" fill="#FFA800"/>
			<path xmlns="http://www.w3.org/2000/svg" d="M50.0246 24.1726C49.6642 23.2144 49.1033 22.3443 48.3794 21.6204C47.6556 20.8966 46.7854 20.3357 45.8273 19.9753C38.8471 17.3539 31.1529 17.3539 24.1727 19.9753C23.2145 20.3357 22.3444 20.8966 21.6205 21.6204C20.8966 22.3443 20.3357 23.2144 19.9754 24.1726C17.3415 31.1499 17.3415 38.8481 19.9754 45.8255C20.3353 46.7841 20.8961 47.6547 21.62 48.3789C22.3439 49.1031 23.2142 49.6641 24.1727 50.0245C31.1506 52.6584 38.8493 52.6584 45.8273 50.0245C46.7856 49.6644 47.6559 49.1036 48.3798 48.3797C49.1037 47.6558 49.6645 46.7855 50.0246 45.8272C52.6585 38.8493 52.6585 31.1505 50.0246 24.1726ZM48.0356 45.0826C47.7817 45.7575 47.3867 46.3703 46.8768 46.8802C46.367 47.39 45.7541 47.7851 45.0793 48.0389C38.5815 50.4802 31.4185 50.4802 24.9207 48.0389C24.2457 47.7847 23.6329 47.3893 23.123 46.8792C22.6132 46.3691 22.2182 45.756 21.9644 45.0809C19.5119 38.5857 19.5119 31.4192 21.9644 24.924C22.2178 24.2485 22.6127 23.635 23.1225 23.1245C23.6324 22.6141 24.2455 22.2185 24.9207 21.9643C31.4164 19.5117 38.5835 19.5117 45.0793 21.9643C45.7541 22.2181 46.367 22.6132 46.8768 23.123C47.3867 23.6329 47.7817 24.2457 48.0356 24.9206C50.4882 31.4163 50.4882 38.5835 48.0356 45.0792V45.0826Z" fill="white"/>
			<path xmlns="http://www.w3.org/2000/svg" d="M41.8 33.9663L31.84023 27.5199C31.6172 27.4001 31.4034 27.3321 31.1832 27.323C30.9629 27.3139 30.7442 27.3641 30.55 27.4682C30.3557 27.5724 30.1929 27.7268 30.0786 27.9153C29.9642 28.1038 29.9026 28.3195 29.9 28.5399V41.4599C29.9 41.6814 29.9598 41.8987 30.0729 42.0891C30.1861 42.2795 30.3484 42.4359 30.5429 42.5418C30.7374 42.6478 30.9568 42.6993 31.1781 42.6911C31.3994 42.6829 31.6145 42.6152 31.8006 42.4952L41.8 36.0335C41.9636 35.9125 42.0989 35.7574 42.1967 35.579C42.2944 35.4005 42.3522 35.2029 42.3661 34.9999C42.3522 34.7969 42.2944 34.5993 42.1967 34.4208C42.0989 34.2424 41.9636 34.0873 41.8 33.9663ZM32.025 39.816V30.1838L39.488 34.9999L32.025 39.816Z" fill="white"/>
		</>
	),
	"FileVideo",
	"0 0 70 70"
);