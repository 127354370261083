import {Avatar, Box, LinearProgress, Typography} from "@mui/material";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import {FC, useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {useFileBaseUrl} from "@plumeuk/shapeshift-common/hooks";
import {SideMenuProfileSection} from "@plumeuk/shapeshift-common/pageBase";
import {redditPalette} from "../../../constants";

const useStyles = makeStyles()((theme) => ({
	container: {
		"& p": {
			color: theme.palette.text.primary
		},
		background: redditPalette.bg,
		height: "100%"
	},
	coverContainer: {
		width: "100%",
		height: "150px"
	},
	avatarContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingBottom: "70px"
	},
	avatar: {
		width: "120px",
		padding: "5px",
		height: "120px",
		marginTop: "-60px",
		borderColor: redditPalette.mint,
		borderWidth: "4px",
		borderStyle: "solid"
	},
	name: {
		color: theme.palette.text.primary,
		fontWeight: "500",
		marginTop: "15px"
	},
	jobRole: {
		color: theme.palette.text.primary,
		fontWeight: "100",
		paddingTop: "5px",
		opacity: 0.8
	},
	progressSection: {
		borderTop: "2px solid " + redditPalette.lightGrey,
		borderBottom: "2px solid " + redditPalette.lightGrey,
		"& [class*='labelsContainer']": {
			flexDirection: "row-reverse"
		}
	},
	badgesContainer: {
		paddingTop: "10px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	badgeSection: {
		borderBottom: "2px solid " + redditPalette.lightGrey,
		"& [class*='title']": {
			fontWeight: "500"
		}
	},
	badge: {
		width: "60px",
		height: "60px",
		objectFit: "contain"
	}
}));

// @TODO: static content. need to add functionality -> https://plume.eu.teamwork.com/app/tasks/19861697

export const DashboardSideBar: FC = () => {
	const baseFileUrl = useFileBaseUrl();
	const {user} = useContext(AuthContext);
	const {classes} = useStyles();

	return (
		<Box className={classes.container}>
			<Box className={classes.coverContainer} />
			<Box className={classes.avatarContainer}>
				<Avatar className={classes.avatar} alt="avatar" src="https://reddit.sfo3.digitaloceanspaces.com/Group%209710.png" />
				<Typography className={classes.name} variant='h5'>{user?.firstname} {user?.lastname}</Typography>
				<Typography className={classes.jobRole}>Junior UI/UX Designer</Typography>
			</Box>
			<SideMenuProfileSection className={classes.progressSection} title="Level 7" secondaryTitle="677/700 points">
				<LinearProgress variant="determinate" value={67} />
			</SideMenuProfileSection>
			<SideMenuProfileSection className={classes.badgeSection} title="Badges" secondaryTitle="See all (29)">
				<Box className={classes.badgesContainer}>
					<img alt="badge" className={classes.badge} src="https://shapeshift.ams3.digitaloceanspaces.com/shapeshift-demo-03-2023%2Fbadge-3.png" />
					<img alt="badge" className={classes.badge} src="https://shapeshift.ams3.digitaloceanspaces.com/shapeshift-demo-03-2023%2Fbadge-4.png" />
					<img alt="badge" className={classes.badge} src="https://shapeshift.ams3.digitaloceanspaces.com/shapeshift-demo-03-2023%2Fbadge-1.png" />
					<img alt="badge" className={classes.badge} src="https://shapeshift.ams3.digitaloceanspaces.com/shapeshift-demo-03-2023%2Fbadge-5.png" />
					<img alt="badge" className={classes.badge} src="https://shapeshift.ams3.digitaloceanspaces.com/shapeshift-demo-03-2023%2Fbadge-2.png" />
				</Box>
			</SideMenuProfileSection>
		</Box>
	);
}