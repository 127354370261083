import SeriesItem from "../../../components/seriesItem/seriesItem"
import {ICourse, IGroupSummary} from "@plumeuk/shapeshift-types";
import {useNavigate} from "react-router-dom";

export interface IProps {
	group: IGroupSummary,
	courses: ICourse[],
	completedView?: boolean //> show completed courses or inprogress
}

export const DashboardSeriesItem: React.FC<IProps> = ({completedView, group, courses}) => {
	const navigate = useNavigate();

	return (
		<SeriesItem
			key={group.slug}
			isEnrolled
			group={group}
			courses={courses}
			completedView={completedView}
			onGoToCourseAction={(e) => navigate("/learn/course/" + e.slug)}
		/>
	);
}