import {Box, BoxProps, Button, Chip, Paper, Typography} from "@mui/material";
import React, {FC, useCallback, useContext, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {ICourse, IFile} from "@plumeuk/shapeshift-types";
import {useSetCourseUnEnrollment} from "@plumeuk/shapeshift-common/hooks";
import {CourseLoadingBar} from "@plumeuk/shapeshift-common/courses";
import {redditPalette} from "../../constants";
import {IconBook} from "../icons/iconBook";
import {IconClock} from "../icons/iconClock";
import ChipGroup from "../chips/chipGroup";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {Image} from "../image";

interface IPropsCustom {
	course: ICourse,
	onGoToCourseAction: () => void,
	onUnenroll?: (slug: string) => void,
	isEnrolled?: boolean
}

export type IProps = BoxProps & IPropsCustom;

const useStyles = makeStyles<{isComplete: boolean}>()((theme, {isComplete}) => ({
	courseItem: {
		marginBottom: "20px",
		overflow: "hidden",
		cursor: "pointer",
		maxWidth: "1020px",
		transition: "transform 0.3s ease-in-out",
		"&:hover": {
			transform: "scale(1.02)"
		}
	},
	paper: {
		padding: "20px",
		border: redditPalette.grey300 + " 1px solid",
		display: "flex",
		borderRadius: "20px",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			padding: "20px"
		}
	},
	imageContainer: {
		overflow: "hidden",
		alignSelf: "center",
		"& img": {
			objectFit: "cover",
			width: "100%",
			height: "100%",
			borderRadius: "15px",
			[theme.breakpoints.down("sm")]: {
				borderRadius: "10px 10px 0 0"
			}
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			height: "300px"
		},
		[theme.breakpoints.up("sm")]: {
			width: "270px",
			height: "200px",
			minWidth: "250px",
			maxWidth: "250px"
		}
	},
	infoContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		padding: "15px 35px 0px 35px",
		flexGrow: 3,
		"& h4": {
			marginBottom: "10px"
		},
		[theme.breakpoints.down("sm")]: {
			padding: "15px 0"
		}
	},
	description: {
		color: redditPalette.grey,
		display: "-webkit-box",
		WebkitLineClamp: 2,
		overflow: "hidden",
		"-webkit-box-orient": "vertical"
	},
	contentContainer: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 3
	},
	loadingBar: {
		gap: "10px",
		paddingBottom: "0 !important",
		"& span": {
			fontWeight: 700
		},
		"& .MuiLinearProgress-root": {
			marginBottom: "0"
		},
		[theme.breakpoints.down("sm")]:{
			padding: "0 !important",
			flexDirection: "column-reverse",

			"& p:after": {
				content: "' completed'"
			}
		}
	},
	tagContainer: {
		marginTop: "10px",
		marginLeft: "30px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "20px",
			marginLeft: "0px"
		}
	},
	unenrollButton: {
		fontSize: "12px",
		lineHeight: 1,
		fontWeight: 600,
		padding: "10px",
		background: redditPalette.grey600,
		border: 0,
		marginLeft: "auto"
	}
}));

export const CourseItem: FC<IProps> = ({className, course, onClick, onUnenroll, onGoToCourseAction, isEnrolled, ...props}) => {
	const {notify} = useContext(NotificationContext);
	const isComplete = course.percentCompleted === 100;
	const {classes, cx} = useStyles({isComplete});

	const {setUnEnroll, response: unEnrollResponse, apiResult: unEnrollApiResult} = useSetCourseUnEnrollment();

	useEffect(() => {
		if (unEnrollResponse) {
			onUnenroll?.(course.slug)
		}
	}, [unEnrollResponse]);


	useEffect(() => {
		if (unEnrollApiResult.statusCode === 403) {
			notify("This is a mandatory course", "Unenrollment not permitted", INotificationSeverity.error, 5000)
		}
	}, [unEnrollApiResult]);


	const handleOnClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		onClick?.(e);

		onGoToCourseAction();
	}, [onGoToCourseAction, onClick, isComplete]);

	const handleUnenrollClick = useCallback((e: React.MouseEvent): void => {
		e.stopPropagation();
		setUnEnroll(course.slug)
	}, [course.slug])

	const getSmallImage = (e: IFile): string => {
		return e.formats?.small?.url ?? e.url;
	}

	return (
		<Box className={cx(classes.courseItem, className)} onClick={handleOnClick} {...props}>
			<Paper className={classes.paper}>
				<Box className={classes.imageContainer}>
					<Image src={getSmallImage(course.featuredImage)} />
				</Box>
				<Box className={classes.contentContainer}>
					<ChipGroup className={classes.tagContainer}>
						<Chip icon={<IconBook />} label="Course" size="small" />
						{course?.timeEstimate && <Chip icon={<IconClock />} label={course.timeEstimate} size="small" />}
						{(onUnenroll && isEnrolled !== false) && <Button className={classes.unenrollButton} size="small" color="primary" onClick={handleUnenrollClick}>Unenroll</Button>}
					</ChipGroup>
					<div>
						<Box className={classes.infoContainer}>
							<Typography variant="h4">{course.title}</Typography>
							<Typography variant="body1" className={classes.description}>{course.description}</Typography>
						</Box>
					</div>

					{isEnrolled &&
						<CourseLoadingBar
							sx={{border: "none !important", flexDirection: "column-reverse !important"}}
							className={classes.loadingBar}
							percentCompleted={course.percentCompleted}
						/>
					}
				</Box>
			</Paper>
		</Box>
	);
};

export default CourseItem;
