import {FC} from "react";
import {Route, Routes, useParams, Outlet} from "react-router-dom";
import {PageBase} from "../../components/pageBase/pageBase";
import {CurriculumProvider} from "../../contexts/curriculumContext";
import {CourseCertificatePage} from "../CourseCertificatePage/CourseCertificatePage";
import {ModulePage} from "../ModulePage/ModulePage";
import {CourseLandingPage} from "./CourseLandingPage";
import {CourseSideMenuContent} from "./CourseSideMenuContent";
import {redditPalette} from "../../constants";
import {makeStyles} from "tss-react/mui";


const useStyles = makeStyles()((theme) => ({
	pageBase:{
		"& [class*='docked-drawer']": {
			height: `calc(100% - ${theme.toolbarHeight})`
		},
		background: redditPalette.bg
	}
}));


const BaseWrap = (): JSX.Element => {
	const {classes} = useStyles();

	return (
		<PageBase className={classes.pageBase} sideBarContent={<CourseSideMenuContent />}>
			<Outlet />
		</PageBase>
	);
}

export const CoursePage: FC = () => {
	const {courseSlug} = useParams();
	return (
		<CurriculumProvider courseSlug={courseSlug}>
			<Routes>
				<Route element={<BaseWrap/>}>
					<Route index element={<CourseLandingPage />}/>
					<Route path="next" element={<CourseLandingPage />} />
					<Route path="complete" element={<CourseCertificatePage />} />
					<Route path=":moduleType/:moduleSlug/*" element={<ModulePage />} />
				</Route>
			</Routes>
		</CurriculumProvider>
	);
}
