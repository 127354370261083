import {Typography} from "@mui/material";
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {useNavigate} from "react-router-dom";
import {DashboardTab} from "../Dashboard";

interface IProps {
	tab: DashboardTab
}

const useStyles = makeStyles()(() => ({
	centerWrapper: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		flexDirection: "column"
	}
}));

// @TODO: static content. need to add functionality -> https://plume.eu.teamwork.com/app/tasks/19861697

export const DashboardNoCourses: FC<IProps> = ({tab}) => {
	const navigate = useNavigate();
	const {classes} = useStyles();

	return (
		<div className={classes.centerWrapper}>
			<Typography variant="h3">{tab === "progress" ? "No courses enrolled" : "No courses completed"}</Typography>
			{tab === "progress" && <Typography variant="body1">Please enroll on some <a onClick={() => navigate("/discover")} href="#">courses</a></Typography> }
		</div>
	);
}