import {FC} from "react";
import {ReactMarkdownProps} from "react-markdown/lib/complex-types";
import EmbedLinkAsIframe from "./embedLinkAsIframe";
import EmbedLinkAsTile from "./embedLinkAsTile";

type Props = Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, "ref"> & ReactMarkdownProps;

export type Platform = "google" | "youtube" | "canva" | "typeform" | null;

function getPlatform(href?: string): Platform {
	if(href?.includes("docs.google.com") || href?.includes("drive.google.com")){
		return "google"
	}
	if(href?.includes("youtube.com")){
		return "youtube"
	}
	if(href?.includes("canva.com")){
		return "canva"
	}
	if(href?.includes("typeform.com")){
		return "typeform"
	}
	return null
}

export const EmbedLink: FC<Props> = (props) => {
	const platform = getPlatform(props.href)

	const embedType = props.children?.[0] === "Link" ? "iframe" : "badge"
	if (platform && embedType === "iframe") {
		return <EmbedLinkAsIframe platform={platform} {...props}/>
	}
	if (embedType === "badge") {
		return <EmbedLinkAsTile {...props}/>
	}

	return <a {...props} rel="noreferrer"href={"https://" + props.href?.replace("https://", "")?.replace("http://", "")} target="_blank" >{props.href}</a>
};

export default EmbedLink;