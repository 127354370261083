import {LibraryContent} from "./LibraryContent";
import {FC} from "react";
import {PageBase} from "../../components/pageBase/pageBase";
import {LibraryBanner} from "./LibraryBanner";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {redditPalette} from "../../constants";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		[theme.breakpoints.up("lg")]:{
			"& [class*='menuIcon']": {
				display: "none"
			},
			"[class*='logoContainer']": {
				"& img": {
					left: "25px"
				}
			}
		},
		"& [class*='contentContainer']": {
			padding: "0"
		}
	},
	contentContainer: {
		backgroundColor: redditPalette.white,
		padding: "25px"
	},
	content: {
		width: "100%",
		maxWidth: "1450px",
		margin: "auto"
	}
}));

export const LibraryPage: FC = () => {
	const {classes, cx} = useStyles();

	return (
		<PageBase contentWidth="100%" className={classes.pageBase} disableSideBar>
			<LibraryBanner />
			<Box className={classes.contentContainer}>
				<Box className={classes.content}>
					<LibraryContent
						sx={{paddingBottom: "70px"}}
					/>
				</Box>
			</Box>
		</PageBase>
	);
}