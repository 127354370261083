import {createSvgIcon} from "@plumeuk/shapeshift-common/icon";

export const IconHome = createSvgIcon(
	<>
		<g id="icon_home_fill">
			<path id="Vector" d="M19.7239 6.76511L10.644 0.654749C10.4575 0.522852 10.2353 0.451398 10.0072 0.449973C9.77912 0.448548 9.55607 0.517218 9.36795 0.646775L0.276137 6.76511C0.165021 6.83953 0.0807888 6.948 0.0359687 7.07438C-0.00885137 7.20076 -0.0118641 7.33829 0.0273787 7.46653C0.0666214 7.59477 0.146023 7.70686 0.253772 7.78612C0.361521 7.86538 0.491861 7.90758 0.625402 7.90644H1.06992V17.9073C1.0715 18.1852 1.18285 18.4512 1.37951 18.6467C1.57616 18.8422 1.84204 18.9513 2.11871 18.9499H7.27831C7.46524 18.9502 7.64464 18.8759 7.7771 18.7434C7.90956 18.6109 7.98425 18.431 7.98478 18.2432V13.7357C7.98478 12.9761 9.12287 12.2614 10.005 12.2614C10.8871 12.2614 12.0251 12.9761 12.0251 13.7357V18.2432C12.0257 18.431 12.1004 18.6109 12.2328 18.7434C12.3653 18.8759 12.5447 18.9502 12.7316 18.9499H17.8912C18.1662 18.9486 18.4295 18.8384 18.624 18.6432C18.8185 18.4479 18.9285 18.1835 18.9301 17.9073V7.90644H19.3746C19.5081 7.90758 19.6385 7.86538 19.7462 7.78612C19.854 7.70686 19.9334 7.59477 19.9726 7.46653C20.0119 7.33829 20.0089 7.20076 19.964 7.07438C19.9192 6.948 19.835 6.83953 19.7239 6.76511Z" fill="#FF4500"/>
		</g>
	</>,
	"Home",
	"0 0 20 20"
);