import {createSvgIcon} from "@plumeuk/shapeshift-common/icon";

export const IconFileImage = createSvgIcon(
	(
		<>
			<path xmlns="http://www.w3.org/2000/svg" d="M0 35C0 6.1775 6.1775 0 35 0C63.8225 0 70 6.1775 70 35C70 63.8225 63.8225 70 35 70C6.1775 70 0 63.8225 0 35Z" fill="#FF4500"/>
			<path xmlns="http://www.w3.org/2000/svg" d="M40.1001 24.7997C39.0914 24.7997 38.1054 25.0988 37.2667 25.6592C36.428 26.2196 35.7743 27.0161 35.3883 27.948C35.0023 28.8799 34.9013 29.9054 35.0981 30.8947C35.2949 31.884 35.7806 32.7927 36.4938 33.506C37.2071 34.2192 38.1158 34.7049 39.1051 34.9017C40.0944 35.0985 41.1199 34.9975 42.0518 34.6115C42.9837 34.2255 43.7802 33.5718 44.3406 32.7331C44.901 31.8944 45.2001 30.9084 45.2001 29.8997C45.2001 28.5471 44.6628 27.2499 43.7063 26.2935C42.7499 25.337 41.4527 24.7997 40.1001 24.7997ZM40.1001 32.8747C39.5117 32.8747 38.9365 32.7002 38.4473 32.3733C37.958 32.0464 37.5767 31.5818 37.3515 31.0382C37.1264 30.4946 37.0674 29.8964 37.1822 29.3193C37.297 28.7422 37.5804 28.2121 37.9964 27.7961C38.4125 27.38 38.9426 27.0967 39.5197 26.9819C40.0968 26.8671 40.6949 26.926 41.2386 27.1512C41.7822 27.3763 42.2468 27.7576 42.5737 28.2469C42.9006 28.7361 43.0751 29.3113 43.0751 29.8997C43.0742 30.6884 42.7604 31.4446 42.2027 32.0024C41.645 32.5601 40.8888 32.8738 40.1001 32.8747Z" fill="white"/>
			<path xmlns="http://www.w3.org/2000/svg" d="M47.5376 19.6997H22.4626C21.7302 19.7006 21.0281 19.9919 20.5102 20.5098C19.9923 21.0277 19.701 21.7298 19.7001 22.4622V47.5372C19.701 48.2696 19.9923 48.9717 20.5102 49.4896C21.0281 50.0075 21.7302 50.2988 22.4626 50.2997H47.5376C48.27 50.2988 48.9721 50.0075 49.49 49.4896C50.0078 48.9717 50.2992 48.2696 50.3001 47.5372V22.4622C50.2992 21.7298 50.0078 21.0277 49.49 20.5098C48.9721 19.9919 48.27 19.7006 47.5376 19.6997ZM21.8251 47.5372V42.9761L26.3301 38.4677C26.6983 38.0991 27.1355 37.8066 27.6168 37.6071C28.0981 37.4075 28.614 37.3048 29.1351 37.3048C29.6561 37.3048 30.172 37.4075 30.6533 37.6071C31.1346 37.8066 31.5719 38.0991 31.9401 38.4677L41.6471 48.1747H22.4626C22.2935 48.1747 22.1313 48.1075 22.0118 47.988C21.8922 47.8684 21.8251 47.7063 21.8251 47.5372ZM48.1751 47.5372C48.1751 47.7063 48.1079 47.8684 47.9884 47.988C47.8688 48.1075 47.7066 48.1747 47.5376 48.1747H44.6476L33.4276 36.9649C32.2858 35.8243 30.7379 35.1837 29.124 35.1837C27.5101 35.1837 25.9623 35.8243 24.8205 36.9649L21.8251 39.9722V22.4622C21.8251 22.2931 21.8922 22.131 22.0118 22.0114C22.1313 21.8919 22.2935 21.8247 22.4626 21.8247H47.5376C47.7066 21.8247 47.8688 21.8919 47.9884 22.0114C48.1079 22.131 48.1751 22.2931 48.1751 22.4622V47.5372Z" fill="white"/>
		</>
	),
	"FileImage",
	"0 0 70 70"
);