import {createSvgIcon} from "@plumeuk/shapeshift-common/icon";

export const IconFileLink = createSvgIcon(
	(
		<>
			<path xmlns="http://www.w3.org/2000/svg" d="M0 35C0 6.1775 6.1775 0 35 0C63.8225 0 70 6.1775 70 35C70 63.8225 63.8225 70 35 70C6.1775 70 0 63.8225 0 35Z" fill="#62C9BF"/>
			<path xmlns="http://www.w3.org/2000/svg" d="M41.9889 39.2511C41.8982 40.7935 41.2387 42.25 40.1344 43.3466C39.3219 44.1474 38.5576 44.9141 37.7934 45.6809C37.0292 46.4476 36.2736 47.211 35.468 48.0032C34.2499 49.1994 32.6004 49.8711 30.8809 49.8711C29.1613 49.8711 27.5118 49.1994 26.2938 48.0032C25.0797 46.8034 24.3979 45.178 24.3979 43.4835C24.3979 41.789 25.0797 40.1637 26.2938 38.9638C27.108 38.1613 27.8723 37.3946 28.6365 36.6278C29.4007 35.8611 30.1546 35.0977 30.962 34.3072C31.9083 33.4201 33.0985 32.827 34.3845 32.6018C35.6704 32.3765 36.9951 32.5292 38.1936 33.0406C38.6525 32.5833 39.12 32.1209 39.5737 31.6703L39.7963 31.4476C38.1613 30.514 36.2584 30.1411 34.3855 30.3872C32.5125 30.6333 30.7752 31.4846 29.4456 32.8077C28.6313 33.6084 27.8671 34.3752 27.1046 35.1419C26.3421 35.9087 25.5848 36.672 24.7757 37.4626C23.1582 39.062 22.25 41.228 22.25 43.4861C22.25 45.7441 23.1582 47.9101 24.7757 49.5095C26.3982 51.1044 28.5962 52 30.8878 52C33.1794 52 35.3774 51.1044 36.9999 49.5095C37.8124 48.7088 38.5749 47.9403 39.3408 47.1736C40.1068 46.4068 40.8607 45.6435 41.6663 44.8512C42.6626 43.868 43.3995 42.6593 43.8127 41.3308C44.2258 40.0023 44.3027 38.5942 44.0366 37.2297L41.9889 39.2511Z" fill="white"/>
			<path xmlns="http://www.w3.org/2000/svg" d="M45.2287 20.4938C43.6076 18.897 41.4094 18 39.1174 18C36.8254 18 34.6273 18.897 33.0062 20.4938C32.1937 21.2946 31.4312 22.063 30.6652 22.8298C29.8993 23.5965 29.1454 24.3599 28.3415 25.1521C27.5354 25.9404 26.8963 26.8787 26.4613 27.9124C26.0262 28.9461 25.8039 30.0548 25.8073 31.1739C25.8095 31.7114 25.8627 32.2476 25.966 32.7754L28.0051 30.7506C28.0958 29.207 28.7567 27.7496 29.8631 26.6533C30.6756 25.8526 31.4398 25.0858 32.204 24.3191C32.9683 23.5523 33.7239 22.789 34.5295 21.9967C35.7459 20.7982 37.3956 20.1248 39.1157 20.1248C40.8358 20.1248 42.4855 20.7982 43.7019 21.9967C44.9174 23.1962 45.6001 24.8221 45.6001 26.5173C45.6001 28.2125 44.9174 29.8384 43.7019 31.0379C42.8894 31.8386 42.1269 32.6054 41.361 33.3721C40.595 34.1389 39.8429 34.9022 39.0355 35.6945C38.0893 36.5819 36.899 37.1751 35.6129 37.4C34.3269 37.625 33.0021 37.4717 31.8038 36.9594C31.3398 37.4235 30.8671 37.891 30.4134 38.3483L30.2046 38.5541C31.8396 39.4873 33.7425 39.8601 35.6154 39.614C37.4883 39.3679 39.2256 38.5168 40.5553 37.194C41.3661 36.3932 42.1304 35.6265 42.8946 34.858C43.6588 34.0896 44.4144 33.3279 45.22 32.5374C46.84 30.9393 47.75 28.7728 47.75 26.5139C47.75 24.255 46.84 22.0885 45.22 20.4904L45.2287 20.4938Z" fill="white"/>
		</>
	),
	"FileLink",
	"0 0 70 70"
);