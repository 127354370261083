import {createTheme, Shadows} from "@mui/material";
import {CustomMuiTheme} from "@plumeuk/shapeshift-common/theming";
import {redditPalette, toolbarHeight, toolbarHeightMobile} from "../constants";

declare module "@mui/material/styles" {
	interface Theme extends CustomMuiTheme {}
	// allow configuration using `createTheme`
	interface ThemeOptions extends CustomMuiTheme{}
	interface BreakpointOverrides {
		xs: true;
		sm: true;
		md: true;
		lg: true;
		xl: true;
		xxl: true;
	}
}

const breakpoints = {
	//Mobile  |		 x < sm
	//Tablet  |	sm < x < md
	//Desktop |		 x > md
	values: {
		// extra-small
		xs: 0,
		// small
		sm: 700,
		// medium
		md: 1000,
		// large
		lg: 1200,
		// extra-large
		xl: 1536,
		// 4k
		xxl: 2000
	}
}

export const defaultTheme = createTheme({
	toolbarHeight,
	breakpoints,
	shadows: Array(25).fill("none") as Shadows,
	palette: {
		background: {
			default: redditPalette.background
		},
		primary: {
			main: redditPalette.white
		},
		secondary: {
			main: redditPalette.orange
		},
		success: {
			main: redditPalette.success
		},
		error: {
			main: redditPalette.error
		}
	},
	typography: {
		fontFamily: "RedditSans, sans-serif",
		body1: {
			fontSize: "16px",
			lineHeight: "170%"
		},
		allVariants: {
			color: redditPalette.textPrimary
		},
		h1: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "50px",
			lineHeight: "120%"
		},
		h2: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "46px",
			lineHeight: "120%"
		},
		h3: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "22px",
			lineHeight: "1.35"
		},
		h4: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "26px",
			lineHeight: "30px"
		},
		body2: {
			fontSize: "9pt"
		},
		h6: {
			fontSize: "20px"
		},
		button: {
			fontSize: "15px",
			textTransform: "none"
		}
	},
	navBar: {
		statusprofile: {
			backgroundColor: "#FF5700"
		}
	},
	components: {
		MuiToolbar: {
			styleOverrides: {
				root: {
					height: toolbarHeight,
					borderBottom: "1px solid " + redditPalette.grey300,
					[`@media only screen and (max-width: ${breakpoints.values.sm})`]: {
						height: toolbarHeightMobile
					}
				}
			}
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					borderRadius: "5px",
					height: "6px",
					backgroundColor: redditPalette.lightGrey
				},
				bar: {
					backgroundImage: `linear-gradient(to right, #FB133A, ${redditPalette.orange},  #FFA800)`,
					borderRadius: "10px"
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					transition: "transform 0.1s ease-in-out",
					"&:hover": {
						transform: "scale(1.04)"
					},
					flexDirection: "row",
					fontWeight: 600,
					gap: "10px",
					fontSize: "16px",
					"&.Mui-selected": {
						color: redditPalette.darkGrey,
						"& img": {
							filter: "invert(35%) sepia(54%) saturate(4793%) hue-rotate(0deg) brightness(102%) contrast(107%)"
						}
					},
					color: redditPalette.darkGrey
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					borderBottom: "1px solid #d1d1d1",
					lineHeight: 0
				},
				indicator: {
					display: "none"
				},
				flexContainer: {
					gap: "30px"

				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					backgroundColor: redditPalette.blue,
					color: "white",
					border: "1px solid " + redditPalette.blue,
					padding: "15px 30px",
					borderRadius: "60px",
					transition: "opacity .3s ease-in-out",
					fontSize: "18px",
					lineHeight: "24px",
					fontWeight: "700",
					"&:hover": {
						backgroundColor: redditPalette.blue,
						opacity: 0.8
					},
					":disabled": {
						opacity: 0.6,
						color: "white"
					}
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				standard: {
					"&:focus": {
						backgroundColor: "transparent"
					}
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					boxSizing: "border-box",
					width: "100%",
					backgroundColor: "#fff",
					padding: "10px 20px",
					borderRadius: "10px",
					border: "1px solid #d1d1d1",
					textAlign: "left",
					"& textarea": {
						textAlign: "left",
						minHeight: "inherit"
					}
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					borderRadius: 100,
					backgroundColor: redditPalette.orange,
					color: redditPalette.darkGrey
				}
			}
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: redditPalette.blue
				}
			}
		},
		MuiContainer: {
			styleOverrides: {
				root: ({theme}) => ({
					[theme.breakpoints.up("lg")]: {
						paddingLeft: "30px",
						paddingRight: "30px"
					}
				})
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				colorPrimary: {
					color: redditPalette.orange + "!important"
				}
			}
		},
		MuiChip: {
			styleOverrides: {
				colorInfo: {
					backgroundColor: redditPalette.grey100,
					color: redditPalette.grey800,
					"&:hover": {
						backgroundColor: redditPalette.success,
						color: "white"
					}
				},
				sizeSmall: ({theme})=> ({
					padding: "6px 12px 6px 10px",
					borderRadius: "50px",
					height: "34px",
					fontSize: "14px",
					lineHeight: 1,
					fontWeight: 600,
					gap: "6px",
					color: redditPalette.textPrimary,
					background: redditPalette.bg
				}),
				labelSmall: {
					display: "inline-block",
					padding: 0,
					color: "inherit"
				},
				iconSmall: {
					display: "inline-block",
					margin: 0,
					color: "inherit"
				},
				sizeMedium: ({theme}) => ({
					height: "56px",
					padding: "18px",
					fontSize: "13px",
					borderRadius: "16px",
					textTransform: "uppercase",
					fontWeight: 700,
					[theme.breakpoints.down("md")]: {
						fontSize: "12px",
						height: "44px"
					}
				}),
				labelMedium: {
					display: "inline-block",
					fontWeight: 700,
					lineHeight: 1,
					color: "inherit"
				}
			}
		}
	}
});
