import React, {FC, useEffect, useState} from "react";
import {AuthProvider as SSIdentityAuthProvider} from "@plumeuk/shapeshift-identity";
import {useOktaAuth} from "@okta/okta-react";
import {IUser} from "@plumeuk/shapeshift-types";

interface IProps {
	children: JSX.Element
}

const AuthProvider: FC<IProps> = ({children}) => {
	const {authState, oktaAuth} = useOktaAuth();
	const logOut = async (): Promise<boolean> => oktaAuth.signOut();
	const [jwt, setJwt] = useState<string>();
	const [user, setUser] = useState<IUser>();

	const getBaseUrl = (): string => {
		const url = process.env.REACT_APP_API_HOST;
		if(!url){
			throw new Error("Please set REACT_APP_API_HOST in .env");
		}
		return url;
	}

	useEffect(() => {
		if(authState?.isAuthenticated && authState?.accessToken){
			setJwt(authState.accessToken.accessToken)
			const claims = authState.idToken?.claims;
			if(claims?.name){
				setUser({
					username: claims?.name,
					email: claims?.email,
					firstname: claims?.name?.split(" ")?.[0],
					lastname: claims?.name?.split(" ")?.[1]
				} as IUser)
			}
		}
	}, [authState?.isAuthenticated, authState?.accessToken])

	const handleLogout = (): Promise<boolean> => logOut();

	return (
		<SSIdentityAuthProvider
			contextConfig={{baseApiUrl: getBaseUrl()}}
			jwt={jwt}
			user={user}
			actionOverrideValidateAuth={(jwt: string | undefined) => !!jwt}
			actionOverrideLogout={handleLogout}
		>
			{children}
		</SSIdentityAuthProvider>
	)
}

export default AuthProvider;