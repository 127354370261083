import {SurveyDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC, useContext, useEffect} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {SurveyRouter} from "./SurveyRouter";
import {CurriculumContext} from "../../../contexts/curriculumContext";

const useStyles = makeStyles()((_theme) => ({
	centeredContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center"
	}
}));

export const SurveyPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: survey} = useParams();
	const {curriculumState: curriculum} = useContext(CurriculumContext);

	return (
		<CenteredContainer className={classes.centeredContainer}>
			<SurveyDataProvider courseSlug={courseSlug} surveySlug={survey} curriculum={curriculum}>
				{({survey}) => <SurveyRouter survey={survey}/>}
			</SurveyDataProvider>
		</CenteredContainer>
	);
}