import {LinearProgress, Typography} from "@mui/material";
import {getFirstModule, getModule} from "@plumeuk/shapeshift-common/common";
import {FC, useContext, useEffect, useState} from "react";
import {useMatch, useNavigate, useParams} from "react-router-dom";
import {CurriculumContext} from "../../contexts/curriculumContext";
import {CenteredContainer} from "../../components/pageBase/centeredContainer";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import ErrorIcon from "@mui/icons-material/Error";

export const CourseLandingPage: FC = () => {
	const nextRoute = useMatch("/learn/course/:courseSlug/next");
	const navigate = useNavigate();
	const {courseSlug} = useParams();
	const {curriculumState} = useContext(CurriculumContext);
	const [errored, setErrored] = useState(false);

	useEffect(() => {
		if(!curriculumState)
			return;

		if(nextRoute && curriculumState && curriculumState.percentCompleted === 100) {
			navigate(`/learn/course/${courseSlug}/complete`);
			return;
		}


		const nextModule = getModule(curriculumState, undefined, true);
		if(nextModule){
			navigate(`/learn/course/${courseSlug}/${nextModule?.type}/${nextModule?.slug}`, {replace: true});
			return
		}
		const firstModule = getFirstModule(curriculumState);
		if(firstModule)
			navigate(`/learn/course/${courseSlug}/${firstModule?.type}/${firstModule?.slug}`, {replace: true});

		else
			setErrored(true)
	}, [curriculumState]);

	return (
		errored ?
			<CenteredContainer >
				<CenterTile
					title={"Something went wrong"}
					icon={<ErrorIcon />}
				>
					<Typography variant="h6" >
						Please try again
					</Typography>
				</CenterTile>
			</CenteredContainer>
			: <LinearProgress />
	);
}