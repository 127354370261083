import {CourseSideMenuContent as CommonCourseSideMenuContent} from "@plumeuk/shapeshift-common/course";
import {moduleSlim, moduleTypeSlim} from "@plumeuk/shapeshift-types/ICourse";
import {FC, useCallback, useContext, useEffect, useState} from "react";
import {Link, useMatch, useNavigate, useParams} from "react-router-dom";
import {CurriculumContext} from "../../contexts/curriculumContext";
import {makeStyles} from "tss-react/mui";
import {redditPalette} from "../../constants";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import CelebrationIcon from "@mui/icons-material/Celebration";
import {Box} from "@mui/material";
import {useMq} from "@plumeuk/shapeshift-common/hooks";

const useStyles = makeStyles<boolean>()((theme, completeRoute) => ({
	sideMenuContent:{
		padding: 0,
		"& > *": {
			padding: "10px"
		},
		"& .section": {
			paddingTop: 0,
			" & [class*='title']": {
				marginLeft: "10px"
			},
			borderBottom: redditPalette.grey300 + " 1px solid"
		},
		"& .isActive > [class*='contentContainer']": {
			borderColor: redditPalette.white,
			background: redditPalette.white
		},
		"& [class*='courseProgressionSummary']": {
			padding: "30px 25px",
			marginBottom: 0,
			borderBottom: redditPalette.grey300 + " 1px solid"
		},

		background: redditPalette.grey100,
		"& [class*='courseTitle'], & [class*='title'], & [class*='percentLabel']": {
			color: "black"
		},
		"& [class*='courseTitle'], .section > [class*='contentContainer'] p": {
			fontWeight: 800
		},
		"& [class*='expandIcon']": {
			color: redditPalette.darkGrey
		},
		"& .module-incomplete [class*='MuiSvgIcon-root']": {
			color: redditPalette.grey + " !important",
			opacity: "1 !important"
		},
		"& .module-complete [class*='MuiSvgIcon-root']": {
			opacity: "1 !important",
			color: redditPalette.orange + " !important",
			border: "1px solid " + redditPalette.orange,
			borderRadius: "100%"
		}
	},
	moduleIcon: {
		width: "10px",
		transform: "scale(2)",
		padding: "0 10px",
		marginRight: "20px"
	},
	completedLink: {
		background: completeRoute ? theme.palette.primary.main : "transparent",
		borderRadius: "10px",
		margin: "10px 35px 10px 25px",
		padding: "5px",
		color: "black",
		textDecoration: "none",
		"& svg": {
			color: redditPalette.orange,
			position: "relative",
			top: "3px",
			fontSize: "20px",
			marginRight: "10px"
		}
	}
}));

interface ICourseSideMenuContentProps {
	setSideMenuOpen?: (open: boolean) => void;
}

export const CourseSideMenuContent: FC<ICourseSideMenuContentProps> = ({setSideMenuOpen}) => {
	const mq = useMq();
	const completeRoute = useMatch("/learn/course/:courseSlug/complete");
	const {classes} = useStyles(!!completeRoute);
	const pageIsModule = useMatch("/learn/course/:courseSlug/:moduleType/:moduleSlug");
	const moduleSlug = pageIsModule?.params.moduleSlug;
	const moduleType = pageIsModule?.params.moduleType;
	const {courseSlug} = useParams();
	const navigate = useNavigate();
	const {curriculumState, curriculumDispatch} = useContext(CurriculumContext);
	const [activeModule, setActiveModule] = useState<moduleSlim>();
	const {notify} = useContext(NotificationContext);

	useEffect(() => {
		if(moduleSlug && moduleType)
			setActiveModule({slug: moduleSlug, type: moduleType as moduleTypeSlim});
	}, [moduleSlug, moduleType]);

	const handleActiveModuleCallback = useCallback((module: ICourseCurriculumModule): void => {

		navigate(`/learn/course/${courseSlug}/${module.type}/${module.slug}`);//, {replace: !pageIsModule}

		if (mq.mobile) {
			setSideMenuOpen?.(false);
		}
	}, [navigate, setSideMenuOpen]);

	const handleSetModuleComplete = useCallback((module: moduleSlim, status: boolean): void => {
		curriculumDispatch({type: "setModuleComplete", module, status})
	}, [curriculumDispatch]);

	const handleModuleForbidden = useCallback((moduleRequest: ICourseCurriculumModule, nextModule?: ICourseCurriculumModule): void => {
		notify(`Modules must be completed in sequence. Next up is ${nextModule?.title}`, "Hey now, slow down!",INotificationSeverity.warning, 5000);
	}, [curriculumDispatch]);

	const typeIcons = [
		{icon: <img className={classes.moduleIcon} src="/icons/play.svg"/>, type: "lesson"},
		{icon: <img className={classes.moduleIcon} src="/icons/quiz.svg"/>, type: "quiz"},
		{icon: <img className={classes.moduleIcon} src="/icons/play.svg"/>, type: "webinar"},
		{icon: <img className={classes.moduleIcon} src="/icons/doc.svg"/>, type: "survey"},
		{icon: <img className={classes.moduleIcon} src="/icons/doc.svg"/>, type: "scorm"}
	]

	return (
		<>
			<CommonCourseSideMenuContent
				typeIcons={typeIcons}
				className={classes.sideMenuContent}
				style={{color: "black"}}
				data={curriculumState}
				courseSlug={courseSlug}
				onModuleForbidden={handleModuleForbidden}
				onActiveChange={handleActiveModuleCallback}
				onModuleComplete={handleSetModuleComplete}
				activeModule={completeRoute ? undefined : activeModule}
			/>
			{curriculumState?.percentCompleted === 100 && <Link
				className={classes.completedLink}
				onClick={() => setActiveModule(undefined)}
				to={"complete"}
			>
				<Box><CelebrationIcon /> Completion!</Box>
			</Link>}
		</>
	);
}