import {Box, BoxProps} from "@mui/material";
import {FC} from "react";

export const ChipGroup: FC<BoxProps> = ({children, ...props}) => {
	return (
		<Box display="flex" flexDirection="row" gap={"10px"} flexWrap="wrap" {...props}>
			{children}
		</Box>
	);
};

export default ChipGroup;