export const sideMenuWidth = "400px";
export const toolbarHeight = "80px";
export const toolbarHeightMobile = "70px";

export const redditPalette = {
	main: "#00315C",
	bg: "#F2F6F7",
	darkGrey: "#2E3436",
	grey100: "#F2F6F7",
	grey200: "#E6E8E9",
	grey300: "#E8EAEB",
	grey400: "#CAD3D5",
	grey500: "#7A9097",
	grey600: "#5A6B6F",
	grey700: "#505a5d",
	grey800: "#3C4447",
	grey: "#2E3436A3",
	lightGrey: "#172E3510",
	textPrimary: "#172E35",
	white: "#FFFFFF",
	orange: "#FF4500",
	blue300: "#0066ff",
	blue400: "#005ce5",
	blue: "#0045AC",
	background: "#F2F1F4",
	error: "#FE4A49",
	success: "#7A9299",
	selected: "#7A9299",
	mint: "#62C9BF"
}