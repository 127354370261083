import {Box, BoxProps, Button, Chip, Dialog, DialogActions, DialogTitle, Paper, Typography} from "@mui/material";
import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {ICourse,IFile,isGroupCurriculumSectionItem} from "@plumeuk/shapeshift-types";
import {useLibraryGroup, useSetGroupUnEnrollment} from "@plumeuk/shapeshift-common/hooks";
import {CourseLoadingBar} from "@plumeuk/shapeshift-common/courses";
import {redditPalette} from "../../constants";
import {IconClock} from "../icons/iconClock";
import ChipGroup from "../chips/chipGroup";
import {IGroupSummary} from "@plumeuk/shapeshift-types/IGroup";
import {IGroupCurriculumItem, isGroupCurriculumCourseItem} from "@plumeuk/shapeshift-types/IGroupCurriculum";
import {Image} from "../image";
import {useNavigate} from "react-router-dom";
import {IconCollection} from "../icons/iconCollection";

interface IPropsCustom {
	group: IGroupSummary,
	courses: ICourse[],
	onGoToCourseAction: (e: ICourse) => void,
	isEnrolled?: boolean,
	completedView?: boolean //> show completed courses or inprogress
}

export type IProps = BoxProps & IPropsCustom;

const useStyles = makeStyles<{isComplete: boolean}>()((theme, {isComplete}) => ({
	seriesItem: {
		marginBottom: "20px",
		overflow: "hidden",
		cursor: "pointer",
		maxWidth: "1020px",
		transition: "transform 0.3s ease-in-out",
		"&:hover": {
			transform: "scale(1.02)"
		}
	},
	paper: {
		padding: "20px",
		border: redditPalette.grey300 + " 1px solid",
		display: "flex",
		borderRadius: "20px",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			padding: "20px"
		}
	},
	imageContainer: {
		position: "relative",
		alignSelf: "center",
		"& img": {
			objectFit: "cover",
			height: "100%",
			borderRadius: "15px",
			[theme.breakpoints.down("sm")]: {
				borderRadius: "10px 10px 0 0"
			}
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			height: "300px"
		},
		[theme.breakpoints.up("sm")]: {
			width: "270px",
			height: "200px",
			minWidth: "250px",
			maxWidth: "250px"
		}
	},
	infoContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		padding: "15px 35px 0px 35px",
		flexGrow: 3,
		"& h4": {
			marginBottom: "10px"
		},
		[theme.breakpoints.down("sm")]: {
			padding: "15px 0"
		}
	},
	description: {
		color: redditPalette.grey,
		display: "-webkit-box",
		WebkitLineClamp: 2,
		overflow: "hidden",
		"-webkit-box-orient": "vertical"
	},
	contentContainer: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 3
	},
	loadingBar: {
		gap: "10px",
		paddingBottom: "0 !important",
		"& span": {
			fontWeight: 700
		},
		"& .MuiLinearProgress-root": {
			marginBottom: "0"
		},
		[theme.breakpoints.down("sm")]:{
			padding: "0 !important",
			flexDirection: "column-reverse",

			"& p:after": {
				content: "' completed'"
			}
		}
	},
	tagContainer: {
		marginTop: "10px",
		marginLeft: "30px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "20px",
			marginLeft: "0px"
		}
	},
	featureImage: {
		width: "100%",
		zIndex: 10,
		position: "absolute",
		borderTop: "3px solid white",
		top: 0
	},
	backingImage: {
		width: "calc(100% - 40px)",
		right: "20px",
		left: "20px",
		zIndex: 0,
		position: "absolute",
		top: "-10px"
	},
	goToCoursesBtn: {
		fontSize: "12px",
		lineHeight: 1,
		fontWeight: 600,
		padding: "10px",
		background: redditPalette.grey600,
		border: 0,
		marginLeft: "auto"
	}
}));

export const SeriesItem: FC<IProps> = ({completedView, className, group, courses, onClick, onGoToCourseAction, isEnrolled, ...props}) => {
	const {group: libraryGroup, getGroup} = useLibraryGroup();
	const [currentCourse, setCurrentCourse] = useState<ICourse>();
	const [nextCourse, setNextCourse] = useState<ICourse>();
	const isComplete = currentCourse?.percentCompleted === 100;
	const {classes, cx} = useStyles({isComplete});
	const [featuredImagedImageLoaded, setFeaturedImagedImageLoaded] = useState(false)
	const navigate = useNavigate();
	const [unEnrollSeriesConfirmOpen, setUnEnrollSeriesConfirmOpen] = useState(false);
	const {response: seriesUnEnrollResponse, setUnEnroll: setSeriesUnEnroll} = useSetGroupUnEnrollment();
	const buttonContainerRef = useRef<HTMLElement>(null);

	useEffect(() => {
		if(group){
			getGroup(group.slug, true, {cache: false})
		}
	}, [group, completedView])

	useEffect(() => {
		if(libraryGroup){
			let current: (undefined | ICourse) = undefined, next: ICourse | undefined = undefined;
			const getNextCourses = (curriculum: IGroupCurriculumItem[]): void=> {
				for(const item of curriculum){
					if(isGroupCurriculumSectionItem(item)){
						getNextCourses(item.items)
					}
					else if(isGroupCurriculumCourseItem(item) && (item.complete === completedView)){
						if(!current)
							current = courses.find(e => e.id === item?.id);
						else if(!next)
							next = courses.find(e => e.id === item?.id);
					}
					if(current && next)
						return;
				}
			}
			getNextCourses(libraryGroup.curriculum)

			if(current){
				setCurrentCourse(current)
			}
			if(next)
				setNextCourse(next)
		}
	}, [libraryGroup, courses])

	const handleOnClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		onClick?.(e);

		if(buttonContainerRef.current?.contains(e?.target as Node))
			return

		if(currentCourse)
			onGoToCourseAction(currentCourse);
	}, [currentCourse, onGoToCourseAction, onClick, isComplete]);

	const handleGroupViewClick = useCallback((e: React.MouseEvent): void => {
		e.stopPropagation();
		navigate(`/discover/collection/${group.slug}`)
	}, [group.slug])

	const handleUnEnrollFromSeries = useCallback((): void => {
		setSeriesUnEnroll(group.slug)
	}, [currentCourse, onGoToCourseAction, onClick, isComplete]);

	const getSmallImage = (e: IFile): string => {
		return e.formats?.small?.url ?? e.url;
	}

	if(seriesUnEnrollResponse)
		return <></>

	return (
		<>
			<Box className={cx(classes.seriesItem, className)} onClick={handleOnClick} {...props}>
				<Paper className={classes.paper}>
					<Box className={classes.imageContainer}>
						{nextCourse?.featuredImage && featuredImagedImageLoaded && <Image className={classes.backingImage} src={getSmallImage(nextCourse?.featuredImage)}/>}
						{currentCourse?.featuredImage && <Image className={classes.featureImage} src={getSmallImage(currentCourse?.featuredImage)} onLoad={() => setFeaturedImagedImageLoaded(true)}/>}
					</Box>
					<Box className={classes.contentContainer}>
						<ChipGroup className={classes.tagContainer}>
							<Chip icon={<IconCollection />} label="Series" size="small" />
							{currentCourse?.timeEstimate && <Chip icon={<IconClock />} label={currentCourse.timeEstimate} size="small" />}
							<Box sx={{flexGrow: 2}}></Box>
							<Box ref={buttonContainerRef} sx={{display: "flex", gap: "10px"}}>
								{isEnrolled !== false && <Button className={classes.goToCoursesBtn} size="small" color="primary" onClick={handleGroupViewClick}>View Series</Button>}
								{isEnrolled !== false && <Button className={classes.goToCoursesBtn} size="small" color="primary" onClick={() => setUnEnrollSeriesConfirmOpen(true)}>Unenroll</Button>}
							</Box>
						</ChipGroup>
						<div>
							<Box className={classes.infoContainer}>
								<Typography variant="h4">{currentCourse?.title}</Typography>
								<Typography variant="body1" className={classes.description}>{currentCourse?.description}</Typography>
							</Box>
						</div>

						{isEnrolled &&
						<CourseLoadingBar
							sx={{border: "none !important", flexDirection: "column-reverse !important"}}
							className={classes.loadingBar}
							percentCompleted={currentCourse?.percentCompleted ?? 0}
						/>
						}
					</Box>
				</Paper>

			</Box>
			<Dialog
				open={unEnrollSeriesConfirmOpen}
				onClose={() => setUnEnrollSeriesConfirmOpen(false)}
				aria-labelledby="un-enroll-series-confirm-dialog"
			>
				<DialogTitle>
					Are you sure you want to unenroll from {group.title}?
				</DialogTitle>
				<DialogActions>
					<Button autoFocus onClick={() => handleUnEnrollFromSeries()}>
						Yes
					</Button>
					<Button onClick={() => setUnEnrollSeriesConfirmOpen(false)} autoFocus>
						No
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SeriesItem;
