import React from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {Dashboard} from "./pages/Dashboard/Dashboard";
import {NotFoundPage} from "./pages/NotFoundPage/NotFoundPage";
import {RequireAuth} from "./routes/RequireAuth";
import {LoginCallback, Security} from "@okta/okta-react";
import {OktaAuth, toRelativeUrl} from "@okta/okta-auth-js";
import AuthProvider from "./AuthProvider";
import {LibraryPage} from "./pages/Library/LibraryPage";
import {CoursePage} from "./pages/CoursePage/CoursePage";
import {CourseProductPage} from "./pages/ProductPage/CourseProductPage";
import {GroupProductPage} from "./pages/ProductPage/GroupProductPage";

if(!process.env.REACT_APP_OKTA_BASE_URL || !process.env.REACT_APP_OKTA_CLIENT_ID){
	throw new Error("REACT_APP_OKTA_BASE_URL and REACT_APP_OKTA_CLIENT_ID are required in env")
}

export const oktaAuthConfig = {
	issuer: process.env.REACT_APP_OKTA_BASE_URL,
	clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
	redirectUri: `${window.location.origin}/login/callback`,
	scopes: ["openid", "profile", "email"],
	pkce: true
};

const oktaAuth = new OktaAuth(oktaAuthConfig);

const Main: React.FC = () => {
	const navigate = useNavigate();

	const restoreOriginalUri = async (_oktaAuth: any, originalUri: any): Promise<void> => {
		navigate(toRelativeUrl(originalUri || "/", window.location.origin));
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<AuthProvider>
				<main style={{height: "100%"}}>
					<div className="App">
						<Routes>
							<Route path="/" element={<RequireAuth><LibraryPage /></RequireAuth>} />
							<Route path="/my-collection" element={<RequireAuth><Dashboard /></RequireAuth>} />
							<Route path="/discover" element={<RequireAuth><LibraryPage/></RequireAuth>} />
							<Route path="/discover/:courseSlug" element={<RequireAuth><CourseProductPage/></RequireAuth>} />
							<Route path="/discover/collection/:groupSlug" element={<RequireAuth><GroupProductPage/></RequireAuth>} />
							<Route path="/discover/collection/:groupSlug/course/:courseSlug" element={<RequireAuth><CourseProductPage/></RequireAuth>} />
							<Route path="/learn/course/:courseSlug/*" element={<RequireAuth><CoursePage/></RequireAuth>} />
							{/* <Route path="/performance" element={<RequireAuth><Performance /></RequireAuth>} /> */}
							{/* <Route path="/feedback" element={<RequireAuth><Feedback /></RequireAuth>} /> */}
							<Route path='/login/callback' element={<LoginCallback />} />
							{/* <Route path='/google-calendar/callback' element={<GoogleCalendarCallback />} /> */}
							{/* <Route path='/google-calendar/callback' element={<GoogleCalendarCallback />} /> */}
							{/* <Route path='/sandbox' element={<Sandbox />} /> */}
							<Route path="*" element={<NotFoundPage />} />
						</Routes>
					</div>
				</main>
			</AuthProvider>
		</Security>
	);
};

export default Main;