import {FC, ReactElement, useContext, useEffect} from "react";
import {useOktaAuth} from "@okta/okta-react";
import {LinearProgress} from "@mui/material";

interface IProps {
	children: ReactElement,
}

export const RequireAuth: FC<IProps> = ({children}) => {
	const {oktaAuth, authState} = useOktaAuth();
	const loggingIn = async (): Promise<void> => oktaAuth.signInWithRedirect({originalUri: window.location.href});

	if(authState && !authState?.isAuthenticated){
		if(!authState?.isPending) {
			loggingIn();
		}
	}

	if (!authState?.isAuthenticated)
		return <LinearProgress />;

	return children;
}
