import {Box, Button} from "@mui/material"
import {IconClose} from "../icons/iconClose"
import {IconSideMenu} from "../icons/iconSideMenu"
import {IconBurgerMenu} from "../icons/iconBurgerMenu"
import {makeStyles} from "tss-react/mui";
import {FC, useState} from "react";
import {useLocation} from "react-router-dom";
import {useMq} from "@plumeuk/shapeshift-common/hooks";

type IProps = {
	sideMenuDisabled: boolean,
	sideMenuOpen: boolean,
	navMenuOpen: boolean,
	setSideMenuOpen: (bool: boolean)=> void,
	setNavMenuOpen: (bool: boolean)=> void,
}

const useStyles = makeStyles()((theme) => ({
	navBarToggleWrapper: {
		display: "flex",
		paddingRight: "8px",
		[theme.breakpoints.up("sm")]:{
			display: "none"
		}
	},
	navBarToggles: {
		background: "none",
		padding: 0,
		width: "40px",
		height: "40px",
		color: "#0F1A1C",
		border: 0,
		opacity: 1,
		minWidth: "40px",
		"&:hover": {
			background: "none"
		}
	},
	menuIconContainer: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column"
	},
	menuIcon: {
		marginRight: "25px",
		cursor: "pointer"
	},
	disabledSpacer: {
		width: "45px"
	}
}));

export const MenuIconContainer: FC<IProps> = ({sideMenuDisabled, sideMenuOpen, navMenuOpen, setSideMenuOpen, setNavMenuOpen}) => {
	const {classes} = useStyles();
	const location = useLocation();
	const [isCoursePage] = useState(location.pathname.includes("/course"));
	const mq = useMq();

	if(sideMenuDisabled) return <Box className={classes.disabledSpacer}></Box>

	if(mq.mobile)
		return (
			<Box className={classes.navBarToggleWrapper}>
				{
					isCoursePage &&
				<Button
					className={classes.navBarToggles}
					onClick={()=> {
						setSideMenuOpen(!sideMenuOpen)
						setNavMenuOpen(false)
					}}
					data-test-id="nav-bar-course-icon"
				>
					{
						sideMenuOpen ? <IconClose /> : <IconSideMenu />
					}
				</Button>
				}
				<Button
					className={classes.navBarToggles}
					onClick={()=> {
						setNavMenuOpen(!navMenuOpen)
						setSideMenuOpen(false)
					}}
					data-test-id="nav-bar-menu-icon"
				>
					{
						navMenuOpen ? <IconClose /> : <IconBurgerMenu />
					}
				</Button>
			</Box>
		)

	return <Box className={classes.menuIconContainer}>
		<IconBurgerMenu data-test-id="nav-bar-menu-icon" onClick={() => setSideMenuOpen(!sideMenuOpen)} className={classes.menuIcon} />
	</Box>
}