import {IScorm} from "@plumeuk/shapeshift-types";
import {FC, useContext, useEffect} from "react";
import {PageBaseContext} from "../../../contexts/pageBaseContext";


interface IProps {
	scorm?: IScorm
}

export const ScormSidebarControl: FC<IProps> = ({scorm}) => {
	const [,setContextState] = useContext(PageBaseContext);

	useEffect(() => {
		if(scorm)
			setContextState(prev => ({
				...prev,
				sidebarEnabled: scorm.sidebarEnabled ?? true,
				sidebarOpen: scorm.sidebarDefaultOpen ?? true
			}))
	}, [scorm])

	return <></>
}