import {FC} from "react";
import {Box, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {IconHome} from "../icons/iconHome";
import {IconLearning} from "../icons/iconTopicLearning";
import {redditPalette} from "../../constants";
import {NavLink} from "react-router-dom";
import {IconSpreadsheetOutline} from "../icons/iconSpreadsheetOutline";
import {useApi} from "@plumeuk/shapeshift-identity";
import {IconComments} from "../icons/iconComments";

type MobileMenuProps = {
	open: boolean,
	onChange: (slug?: string)=> void
}

const routes = [
	{
		label: "My Collection",
		slug: "/my-collection",
		icon: <IconHome className="routeIcon"/>
	},
	{
		label: "Discover",
		slug: "/discover",
		icon: <IconLearning className="routeIcon"/>
	},
	{
		label: "Help",
		slug: process.env.REACT_APP_HELP_URL ?? "",
		icon: <IconComments className="routeIcon"/>
	},
	{
		label: "CMS",
		slug: process.env.REACT_APP_API_HOST ?? "",
		icon: <IconSpreadsheetOutline className="routeIcon"/>
	}
]

const useMenuStyles = makeStyles()((theme) => ({
	mobileMenu: {
		position: "fixed",
		top: theme.toolbarHeight,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 5
	},
	menuItem: {
		display: "flex",
		alignItems: "center",
		color: redditPalette.darkGrey,
		gap: "15px",
		fontSize: "16px",
		lineHeight: "100%",
		fontWeight: 600,
		padding: "13px",
		textDecoration: "none",
		"& [class*='routeIcon']": {
			fontSize: "20px",
			display: "inline-block"
		},
		"&.isActive": {
			fontWeight: 800
		}
	},
	menuNav: {
		position: "fixed",
		top: theme.toolbarHeight,
		left: 0,
		right: 0,
		width: "100%",
		background: "white",
		padding: "21px 42px 38px 42px",
		boxSizing: "border-box"
	},
	menuBackground: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		background: redditPalette.textPrimary,
		opacity: 0.5
	}
}));

export const MobileMenu: FC<MobileMenuProps> = ({open, onChange})=> {
	const {classes} = useMenuStyles()
	const [{data: hasCMSAccess}] = useApi<boolean>("/api/access/cms");

	if (!open) return <></>

	return (
		<Box className={classes.mobileMenu}>
			<Box className={classes.menuBackground} onClick={()=> onChange(undefined)}></Box>
			<Box className={classes.menuNav}>
				{
					routes.filter(e => hasCMSAccess || e.label !== "CMS").map((route)=> (
						<NavLink to={route.slug} onClick={()=> onChange(route.slug)} key={route.slug} className={({isActive}) => `${classes.menuItem} ${isActive && "isActive"}`}>
							{route.icon}
							<Typography variant="inherit" color="inherit">{route.label}</Typography>
						</NavLink>
					))
				}
			</Box>
		</Box>
	)
}