import {Box, Chip, Typography} from "@mui/material";
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {ITag, ILibrarySearch} from "@plumeuk/shapeshift-types";

interface IProps {
	tags: ITag[],
	onChange: React.Dispatch<React.SetStateAction<ILibrarySearch>>,
	value: ILibrarySearch["tags"],
}

const useStyles = makeStyles()((theme) => ({
	tags: {
		margin: "0 10px",
		marginBottom: "48px",
		[theme.breakpoints.down("md")]: {
			margin: "0",
			marginBottom: "44px"
		}
	},
	title: {
		marginLeft: "29px",
		marginBottom: "28px",
		fontSize: "21px",
		lineHeight: "27px",
		fontWeight: "700",
		[theme.breakpoints.down("md")]: {
			marginLeft: "0px",
			marginBottom: "17px",
			fontSize: "16px",
			lineHeight: "130%",
			fontWeight: "700"
		}
	},
	tagsContainer: {
		display: "flex",
		gap: "6px",
		flexWrap: "wrap",
		[theme.breakpoints.down("md")]: {
			gap: "10px"
		}
	},
	tagIconContainer: {
		height: "100%",
		justifyContent: "center",
		display: "flex",
		flexDirection: "column"
	},
	tagIcon: {
		marginRight: "10px",
		width: "20px"
	}
}));


export const LibraryTags: FC<IProps> = ({tags, value, onChange}) => {
	const {classes} = useStyles();

	const handleTagClick = (tag: ITag): void => {
		onChange(prev => {
			if(!prev.tags){
				return {...prev, tags: [tag.id]}
			}

			//remove tag
			if(prev.tags.includes(tag.id)){
				const newTags = prev.tags.filter(e => e !== tag.id);
				return {...prev, tags: [...newTags]};
			}

			//add tag
			return {...prev, tags: [...prev.tags, tag.id]};
		})
	}

	return (
		<Box className={classes.tags}>
			<Typography className={classes.title} variant="body2">Popular Tags</Typography>
			<Box className={classes.tagsContainer}>
				{
					tags?.map((tag) => {
						return (
							<Chip
								key={tag.id}
								color={value?.includes(tag.id) ? "success" : "info"}
								label={tag.title}
								variant="filled"
								icon={tag.icon ? <Box className={classes.tagIconContainer}><img className={classes.tagIcon} src={tag.icon} /></Box> : undefined}
								onClick={() => handleTagClick(tag)}
							/>
						)
					})
				}
			</Box>
		</Box>
	)
}