import {VideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";

interface ICustomProps {
	videoUrl?: string
}

interface IStyleProps {
	videoWidth: string,
	videoHeight: string,
	videoSmallHeight: string
}

const useStyles = makeStyles<IStyleProps>()((theme, {videoWidth, videoHeight, videoSmallHeight}) => ({

	videoPlayer: {
		zIndex: 5,
		transition: "opacity .5s ease-in",
		"& iframe": {
			borderRadius: "20px"
		}
	},
	videoPlayerSpacer: {
		borderRadius: "20px"
	}
}));

type IProps = ICustomProps;

export const LibraryBannerVideo: FC<IProps> = ({videoUrl}) => {
	const {classes} = useStyles({
		videoWidth: "calc(100% - 40px) !important",
		videoHeight: "100% !important",
		videoSmallHeight: "250px"
	});
	const mq = useMq()
	const [videoReady, setVideoReady] = useState(false);
	if (!videoUrl) return <></>

	return (
		<>
			<VideoPlayer
				onReady={() => setVideoReady(true)}
				className={classes.videoPlayer}
				style={{opacity: videoReady ? 1 : 0}}
				url={videoUrl}
				controls={true}
				height={mq.mobile ? "250px" : "475px"}
			/>
			<div className={classes.videoPlayerSpacer}/>
		</>
	);
}