import {Box, Chip, Typography} from "@mui/material"
import {FC} from "react"
import {makeStyles} from "tss-react/mui";
import {IconBook} from "../icons/iconBook";
import {IconClock} from "../icons/iconClock";
import ChipGroup from "../chips/chipGroup";
import {ILibraryItem, isILibraryCourse, isILibraryGroup} from "@plumeuk/shapeshift-types";
import {IconCollection} from "../icons/iconCollection";

interface IProps {
	item: ILibraryItem
}

const useStyles = makeStyles()((theme) => ({
	content: {
		background: theme.palette.background.paper,
		padding: "25px 30px 40px 30px",
		flexGrow: 2,
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			padding: "20px",
			paddingBottom: "40px"
		}
	},
	title: {
		fontWeight: 700,
		fontSize: "22px",
		lineHeight: "130%",
		[theme.breakpoints.down("sm")]: {
			fontSize: "18px"
		}
	},
	description: {
		marginTop: "7px",
		color: "rgba(23, 46, 53, 0.70)"
	}
}));


export const LibraryCarouselItem:FC<IProps> = ({item}) => {
	const {classes} = useStyles();

	return (
		<Box className={classes.content}>
			{isILibraryCourse(item) && <ChipGroup marginBottom={["16px", "19px"]}>
				<Chip color="default" label="Course" size="small" icon={<IconBook fontSize="small" />}/>
				{
					item?.timeEstimate &&
					<Chip color="default" label={item.timeEstimate} icon={<IconClock fontSize="small"/>} size="small"/>
				}
			</ChipGroup>}
			{isILibraryGroup(item) && <ChipGroup marginBottom={["16px", "19px"]}>
				<Chip color="default" label="Series" size="small" icon={<IconCollection fontSize="small" />}/>
			</ChipGroup>}
			<Typography className={classes.title} variant="h3">{item.title}</Typography>
			<Typography className={classes.description}>{(item?.description?.length ?? 0) > 120 ? `${item.description?.substring(0,100)?.trim() ?? ""}...` : item.description}</Typography>
		</Box>
	)
}