import {FC} from "react";
import {makeStyles} from "tss-react/mui";

type IProps = {
	src?: string
};

const useStyles = makeStyles()((theme) => ({
	libraryBannerImage: {
		width: "100%",
		maxHeight: "500px", // random value so height doesnt go overboard
		borderRadius: "20px",
		objectFit: "cover",
		objectPosition: "center",
		display: "block"
	}
}));

export const LibraryBannerImage: FC<IProps> = ({src}) => {
	const {classes} = useStyles();

	return (
		<img
			className={classes.libraryBannerImage}
			src={src}
		/>
	);
}