import {Button, Typography} from "@mui/material";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import Confetti from "react-confetti"

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		[theme.breakpoints.down("sm")]: {
			paddingTop: "60px"

		},
		[theme.breakpoints.up("sm")]: {
			"& button": {
				width: "190px",
				height: "52px"
			}
		},
		textAlign: "center"
	},
	tagLine: {
		color: "#2A2A2F",
		fontWeight: 400
	},
	dashBoardBtn: {},
	file: {
		textAlign: "left",
		margin: "30px 0",
		[theme.breakpoints.down("sm")]: {
			background: "#BCBCBD33",
			textAlign: "inherit",
			"& > div:first-child": {
				display: "none"
			},
			"& h6": {
				fontSize: "22px"
			},
			"& [class*='downloadButton']": {
				borderTop: "solid 2px #d1d1d180"
			}
		}
	},
	confetti: {
		width: "100vw",
		height: `calc(100vh - ${theme.toolbarHeight})`
	}
}));

export const CourseCertificatePage: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();

	const goToDashboardBtn = <Button onClick={() => navigate("/discover")} className={classes.dashBoardBtn}>go back to the Discover page</Button>;

	return (
		<>
			<CenterTile
				className={classes.centeredContainer}
				title="Congratulations"
				icon={<WorkspacePremiumOutlinedIcon />}
				buttons={[goToDashboardBtn]}
			>
				<Typography variant="h6" className={classes.tagLine}>
					You finished the course!
				</Typography>
				{/* <CourseCertificate courseSlug={courseSlug} /> */}

			</CenterTile>
			<Confetti className={classes.confetti}/>
		</>
	);
}